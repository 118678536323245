import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { IntegrationType } from '@squidcloud/client';
import { truthy } from 'assertic';
import { Integrations } from '@squidcloud/console-web/app/integrations/utils/content';
import { AbilityMetadata } from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';

type CreateOptions = {
  integrationType: string;
};

type Options = {
  integrationType: string;
  integrationId: string;
};

export class ConnectorAbility extends BaseAbility<CreateOptions, Options, Options> {
  override async onCreate(agentId: string, options: CreateOptions): Promise<boolean> {
    const { integrationType } = options;
    const application = this.applicationService.getCurrentApplicationOrFail();
    await this.router.navigate(['/application', application.appId, 'integrations', 'add', integrationType], {
      queryParams: { agentId },
    });
    return true;
  }

  override async onConnect(agentId: string, options: Options): Promise<boolean> {
    const agent = this.studioService.getAgentSchemaOrFail(agentId);
    const { integrationType } = options;
    const isTypeAdded = !agent.connectedIntegrations?.find(i => i.integrationType === integrationType);

    await this.updateConnector(agentId, options, false);
    const application = this.applicationService.getCurrentApplicationOrFail();

    if (isTypeAdded) {
      await callBackendExecutable(this.squid, 'redeployApplicationCode', application.appId);
    }
    return true;
  }

  override async onEdit(agentId: string, options: Options): Promise<boolean> {
    return this.updateConnector(agentId, options, true);
  }

  private async updateConnector(agentId: string, options: Options, isEdit: boolean): Promise<boolean> {
    const { integrationId, integrationType } = options;
    const agent = this.studioService.getAgentSchemaOrFail(agentId);
    const currentIntegrations = [...(agent.connectedIntegrations || [])];
    const connectedIntegration = currentIntegrations.find(i => i.integrationId === integrationId);

    if (!isEdit && connectedIntegration) {
      return false;
    }

    return new Promise((resolve, reject) => {
      this.globalUiService.showDialogWithForm<{ description: string }>(
        {
          title: `${isEdit ? 'Edit' : 'Connect'} ${integrationId}`,
          textLines: [`Describe to the agent when to use this connector.`],
          submitButtonText: `${isEdit ? 'Save' : 'Add'} Connector`,
          formElements: [
            {
              type: 'textarea',
              label: 'Description',
              nameInForm: 'description',
              placeholder: 'Call this when a user asks for shipping status updates.',
              floatLabel: 'always',
              required: true,
              attributes: { autosize: true, minRows: 3, maxRows: 50 },
              defaultValue: connectedIntegration?.description,
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            try {
              if (!data) return;
              const { description } = data;
              const agent = this.studioService.getAgentSchemaOrFail(agentId);
              const currentIntegrations = [...(agent.connectedIntegrations || [])];

              if (isEdit) {
                const connectedIntegration = truthy(currentIntegrations.find(i => i.integrationId === integrationId));
                connectedIntegration.description = description;
              } else {
                currentIntegrations.push({
                  integrationId,
                  integrationType: integrationType as IntegrationType,
                  description,
                });
              }

              agent.connectedIntegrations = currentIntegrations;
              await this.studioService.updateAgent(agentId, agent);
              resolve(true);
            } catch (e) {
              reject(e);
            }
          },
        },
        true,
      );
    });
  }

  override async onDelete(agentId: string, options: Options): Promise<boolean> {
    const { integrationId, integrationType } = options;

    return new Promise(async (resolve, reject) => {
      this.globalUiService.showConfirmationDialog(
        'Remove connector?',
        `Are you sure you want to remove the <b>${integrationId}</b> connector?`,
        'Remove',
        async () => {
          const agent = this.studioService.getAgentSchemaOrFail(agentId);
          const currentIntegrations = [...(agent.connectedIntegrations || [])];
          const index = currentIntegrations.findIndex(i => i.integrationId === integrationId);
          currentIntegrations.splice(index, 1);

          agent.connectedIntegrations = currentIntegrations;
          const isTypeRemoved = !agent.connectedIntegrations.find(i => i.integrationType === integrationType);
          try {
            const application = this.applicationService.getCurrentApplicationOrFail();
            await this.studioService.updateAgent(agentId, agent);

            if (isTypeRemoved) {
              await callBackendExecutable(this.squid, 'redeployApplicationCode', application.appId);
            }
            resolve(true);
          } catch (e) {
            reject(e);
          }
        },
      );
    });
  }

  static getAbilityMetadata(type: IntegrationType): AbilityMetadata {
    return {
      name: Integrations[type].name,
      description: Integrations[type].description,
      icon: type,
    };
  }
}
