<div class="category">
  <button class="header" (click)="handleToggle()">
    <span class="title">{{ title }}</span>
    <mat-icon [ngClass]="{ collapsed: !expanded }" class="toggle_icon" svgIcon="chevron_right_icon"></mat-icon>
  </button>
  <div [ngClass]="{ collapsed: !expanded }" class="content_wrapper">
    <div class="content">
      <ng-content />
    </div>
  </div>
</div>
