import {
  Auth0IntegrationConfig,
  CognitoIntegrationConfig,
  DescopeIntegrationConfig,
  FirebaseAuthIntegrationConfig,
  JwtHmacIntegrationConfig,
  JwtRsaIntegrationConfig,
  OktaIntegrationConfig,
} from './auth.types';
import {
  BigQueryIntegrationConfig,
  ClickHouseIntegrationConfig,
  CockroachIntegrationConfig,
  IntegrationDataSchema,
  InternalIntegrationConfig,
  MongoIntegrationConfig,
  MssqlIntegrationConfig,
  MySqlIntegrationConfig,
  OracleIntegrationConfig,
  PostgresIntegrationConfig,
  SapHanaIntegrationConfig,
  SnowflakeIntegrationConfig,
} from './database.types';
import { DatadogIntegrationConfig, NewRelicIntegrationConfig } from './observability.types';
import {
  AUTH_INTEGRATION_TYPES,
  DATA_INTEGRATION_TYPES,
  GRAPHQL_INTEGRATION_TYPES,
  HTTP_INTEGRATION_TYPES,
  IntegrationSchemaType,
  IntegrationType,
} from '../../public-types/integration.public-types';
import { BuiltInQueueIntegrationConfig, ConfluentIntegrationConfig, KafkaIntegrationConfig } from './queue-types';
import { IntegrationId } from '../../public-types/communication.public-types';
import { AiAgentsIntegrationConfig, AiChatbotIntegrationConfig } from './ai_chatbot.types';
import {
  ConfluenceIntegrationConfig,
  DefaultGraphQLIntegrationConfig,
  DefaultHttpApiIntegrationConfig,
  IntegrationApiSchema,
  IntegrationGraphQLSchema,
  JiraIntegrationConfig,
  LinearIntegrationConfig,
  SalesforceCrmIntegrationConfig,
  ServiceNowIntegrationConfig,
  ZendeskIntegrationConfig,
} from './api.types';
import {
  BuiltInGcsIntegrationConfig,
  BuiltInS3IntegrationConfig,
  GcsIntegrationConfig,
  GoogleDriveIntegrationConfig,
  S3IntegrationConfig,
} from './storage-types';

/** @internal. */
export interface IntegrationConfigTypes {
  ai_agents: AiAgentsIntegrationConfig;
  ai_chatbot: AiChatbotIntegrationConfig;
  api: DefaultHttpApiIntegrationConfig;
  auth0: Auth0IntegrationConfig;
  bigquery: BigQueryIntegrationConfig;
  built_in_db: InternalIntegrationConfig;
  built_in_gcs: BuiltInGcsIntegrationConfig;
  built_in_queue: BuiltInQueueIntegrationConfig;
  built_in_s3: BuiltInS3IntegrationConfig;
  clickhouse: ClickHouseIntegrationConfig;
  cockroach: CockroachIntegrationConfig;
  cognito: CognitoIntegrationConfig;
  confluence: ConfluenceIntegrationConfig;
  confluent: ConfluentIntegrationConfig;
  datadog: DatadogIntegrationConfig;
  descope: DescopeIntegrationConfig;
  firebase_auth: FirebaseAuthIntegrationConfig;
  gcs: GcsIntegrationConfig;
  google_drive: GoogleDriveIntegrationConfig;
  graphql: DefaultGraphQLIntegrationConfig;
  jira: JiraIntegrationConfig;
  jwt_hmac: JwtHmacIntegrationConfig;
  jwt_rsa: JwtRsaIntegrationConfig;
  kafka: KafkaIntegrationConfig;
  linear: LinearIntegrationConfig;
  mongo: MongoIntegrationConfig;
  mssql: MssqlIntegrationConfig;
  mysql: MySqlIntegrationConfig;
  newrelic: NewRelicIntegrationConfig;
  okta: OktaIntegrationConfig;
  oracledb: OracleIntegrationConfig;
  postgres: PostgresIntegrationConfig;
  s3: S3IntegrationConfig;
  salesforce_crm: SalesforceCrmIntegrationConfig;
  sap_hana: SapHanaIntegrationConfig;
  servicenow: ServiceNowIntegrationConfig;
  snowflake: SnowflakeIntegrationConfig;
  zendesk: ZendeskIntegrationConfig;
}

/** @internal. */
export interface IntegrationSchemaTypes {
  api: IntegrationApiSchema;
  bigquery: IntegrationDataSchema;
  built_in_db: IntegrationDataSchema;
  clickhouse: IntegrationDataSchema;
  cockroach: IntegrationDataSchema;
  graphql: IntegrationGraphQLSchema;
  mongo: IntegrationDataSchema;
  mssql: IntegrationDataSchema;
  mysql: IntegrationDataSchema;
  oracledb: IntegrationDataSchema;
  postgres: IntegrationDataSchema;
  sap_hana: IntegrationDataSchema;
  snowflake: IntegrationDataSchema;
}

/** @internal. */
export type IntegrationTypeWithConfig = keyof IntegrationConfigTypes;
/** @internal. */
export type IntegrationSchemaKeys = keyof IntegrationSchemaTypes;

/** @internal. */
export type IntegrationConfig = IntegrationConfigTypes[IntegrationTypeWithConfig];
/** @internal. */
export type IntegrationSchema = IntegrationSchemaTypes[IntegrationSchemaKeys];

/** @internal. */
export type DatabaseIntegrationType = (typeof DATA_INTEGRATION_TYPES)[number];
/** @internal. */
export type DatabaseIntegrationConfig = IntegrationConfigTypes[DatabaseIntegrationType];

/** @internal. */

/** @internal. */
export type AuthIntegrationType = (typeof AUTH_INTEGRATION_TYPES)[number];
/** @internal. */
export type AuthIntegrationConfig = IntegrationConfigTypes[AuthIntegrationType];

/** @internal. */

/** @internal. */
export type HttpApiIntegrationType = (typeof HTTP_INTEGRATION_TYPES)[number];
/** @internal. */
export type HttpApiIntegrationConfig = IntegrationConfigTypes[HttpApiIntegrationType];

/** @internal. */

/** @internal. */
export type GraphQLIntegrationType = (typeof GRAPHQL_INTEGRATION_TYPES)[number];
/** @internal. */
export type GraphQLIntegrationConfig = IntegrationConfigTypes[GraphQLIntegrationType];

/** @internal. */
export const AI_INTEGRATION_TYPES = ['ai_agents', 'ai_chatbot'] as const;

export type AiIntegrationType = (typeof AI_INTEGRATION_TYPES)[number];

/** @internal. */
export function isDataIntegrationType(type: unknown): type is DatabaseIntegrationType {
  return DATA_INTEGRATION_TYPES.includes(type as DatabaseIntegrationType);
}

/** @internal. */
export function isAuthIntegrationType(type: unknown): type is AuthIntegrationType {
  return AUTH_INTEGRATION_TYPES.includes(type as AuthIntegrationType);
}

/** @internal. */
export function isApiIntegrationType(type: unknown): type is HttpApiIntegrationType {
  return HTTP_INTEGRATION_TYPES.includes(type as HttpApiIntegrationType);
}

/** @internal. */
export function isGraphQLIntegrationType(type: unknown): type is GraphQLIntegrationType {
  return GRAPHQL_INTEGRATION_TYPES.includes(type as GraphQLIntegrationType);
}

/** @internal. */
export function isAiIntegrationType(type: unknown): type is AiIntegrationType {
  return (AI_INTEGRATION_TYPES as readonly IntegrationType[]).includes(type as IntegrationType);
}

/** @internal. */
export function isAuthIntegration(integration: unknown): integration is AuthIntegrationConfig {
  return isAuthIntegrationType((integration as AuthIntegrationConfig)?.type);
}

/** @internal. */
export interface BaseIntegrationConfig {
  id: IntegrationId;
  type: IntegrationType;
  creationDate?: Date;
  updateDate?: Date;
}

/**
 * Any integration config that requires configuration beyond BaseIntegrationConfig should use this common object layout.
 *  @internal.
 * */
export interface BaseIntegrationConfigWithConfiguration<ConfigurationType extends object>
  extends BaseIntegrationConfig {
  configuration: ConfigurationType;
}

/*** @internal. */
export function isBaseIntegrationConfigWithConfiguration(
  obj: unknown,
): obj is BaseIntegrationConfigWithConfiguration<object> {
  if (!obj || typeof obj !== 'object') return false;
  const { configuration } = obj as BaseIntegrationConfigWithConfiguration<object>;
  return !!configuration && typeof configuration === 'object' && !Array.isArray(configuration);
}

/** @internal. */
export interface IntegrationConfigurationWithConnectionOptions<ConnectionOptionsType extends object> {
  connectionOptions: ConnectionOptionsType;
}

/** @internal. */
export function isIntegrationConfigurationWithConnectionOptions(
  obj: unknown,
): obj is IntegrationConfigurationWithConnectionOptions<object> {
  if (!obj || typeof obj !== 'object') return false;
  const { connectionOptions } = obj as IntegrationConfigurationWithConnectionOptions<object>;
  return !!connectionOptions && typeof connectionOptions === 'object' && !Array.isArray(connectionOptions);
}

/** @internal. */
export interface ConnectionOptionsPasswordAsSecrets {
  password: string;
}

/** @internal. */
export interface CommonConnectionPrivateKeyAsSecrets {
  privateKey: string;
}

/** @internal. */
export interface ConnectionOptionsWithSecrets<SecretsType> {
  secrets: SecretsType;
}

/** @internal. */
export function isConnectionOptionsWithSecrets(obj: unknown): obj is ConnectionOptionsWithSecrets<unknown> {
  if (!obj || typeof obj !== 'object') return false;
  const { secrets } = obj as ConnectionOptionsWithSecrets<unknown>;
  return !!secrets && typeof secrets === 'object' && !Array.isArray(secrets);
}

/** @internal. */
export interface BaseIntegrationSchema {
  type: IntegrationSchemaType;
}

/** @internal. */
export function isNoSqlDatabase(type: IntegrationType): boolean {
  return type === 'mongo' || type === 'built_in_db';
}
