import { IntegrationId, Paths } from '@squidcloud/client';
import { AiChatbotIntegrationConfig } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import {
  ConfluenceIntegrationConfig,
  DefaultGraphQLIntegrationConfig,
  DefaultHttpApiIntegrationConfig,
  JiraIntegrationConfig,
  LinearIntegrationConfig,
  SalesforceCrmIntegrationConfig,
  ServiceNowIntegrationConfig,
  ZendeskIntegrationConfig,
} from '@squidcloud/internal-common/types/integrations/api.types';
import {
  Auth0IntegrationConfig,
  CognitoIntegrationConfig,
  DescopeIntegrationConfig,
  FirebaseAuthIntegrationConfig,
  JwtHmacIntegrationConfig,
  JwtRsaIntegrationConfig,
  OktaIntegrationConfig,
} from '@squidcloud/internal-common/types/integrations/auth.types';
import {
  BigQueryIntegrationConfig,
  ClickHouseIntegrationConfig,
  CockroachIntegrationConfig,
  MongoIntegrationConfig,
  MssqlIntegrationConfig,
  MySqlIntegrationConfig,
  OracleIntegrationConfig,
  PostgresIntegrationConfig,
  SapHanaIntegrationConfig,
  SnowflakeIntegrationConfig,
} from '@squidcloud/internal-common/types/integrations/database.types';
import {
  DatadogIntegrationConfig,
  datadogRegionMap,
  NewRelicIntegrationConfig,
  newRelicRegionMap,
} from '@squidcloud/internal-common/types/integrations/observability.types';
import { KafkaIntegrationConfig } from '@squidcloud/internal-common/types/integrations/queue-types';
import { IntegrationConfig, IntegrationTypeWithConfig } from '@squidcloud/internal-common/types/integrations/schemas';
import { isValidId } from '@squidcloud/internal-common/utils/validation';
import {
  GcsIntegrationConfig,
  GoogleDriveIntegrationConfig,
  S3IntegrationConfig,
} from '@squidcloud/internal-common/types/integrations/storage-types';

export type IntegrationFormFieldType = 'regular' | 'secret' | 'boolean' | 'select' | 'component';

export interface IntegrationFormSelectOption {
  value: string;
  label: string;
}

interface BaseIntegrationFormField<T extends IntegrationConfig> {
  type: IntegrationFormFieldType;
  name: string;
  required: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator?: (value: any) => string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  pathInIntegration: Paths<T> | string;
  tooltip?: IntegrationFormTooltip;
  prefixIcon?: string;
  description?: string;
  dataTestId?: string;
}

export interface InputIntegrationFormField<T extends IntegrationConfig> extends BaseIntegrationFormField<T> {
  type: 'regular';
  placeholder: string;
}

export interface BooleanIntegrationFormField<T extends IntegrationConfig> extends BaseIntegrationFormField<T> {
  type: 'boolean';
}

export interface SecretIntegrationFormField<T extends IntegrationConfig> extends BaseIntegrationFormField<T> {
  type: 'secret';
  placeholder: string;
}

export interface SelectIntegrationFormField<T extends IntegrationConfig> extends BaseIntegrationFormField<T> {
  type: 'select';
  placeholder?: string;
  options: Array<IntegrationFormSelectOption>;
}

export interface ComponentIntegrationFormField<T extends IntegrationConfig> extends BaseIntegrationFormField<T> {
  type: 'component';
  component: {
    type: string;
  };
}

export type IntegrationFormField<T extends IntegrationConfig = IntegrationConfig> =
  | InputIntegrationFormField<T>
  | BooleanIntegrationFormField<T>
  | SecretIntegrationFormField<T>
  | SelectIntegrationFormField<T>
  | ComponentIntegrationFormField<T>;

export interface IntegrationFormTooltip {
  copy: string;
  examples?: Array<string>;
}

interface BaseIntegrationFormSection<T extends IntegrationConfig = IntegrationConfig> {
  type: 'toggleable' | 'default';
  fields: Array<IntegrationFormField<T>>;
}

export interface DefaultIntegrationFormSection<T extends IntegrationConfig = IntegrationConfig>
  extends BaseIntegrationFormSection<T> {
  type: 'default';
}

export interface ToggleableIntegrationFormSection<T extends IntegrationConfig = IntegrationConfig>
  extends BaseIntegrationFormSection<T> {
  type: 'toggleable';
  enabled: boolean;
  title: string;
  description?: string;
  pathInIntegration?: Paths<T> | string;
  /**
   * If set will store boolean flag value to 'pathInIntegration' field.
   */
  saveToggleStateToIntegrationConfig?: boolean;
}

export type IntegrationFormSection<T extends IntegrationConfig> =
  | DefaultIntegrationFormSection<T>
  | ToggleableIntegrationFormSection<T>;

export interface IntegrationForm<T extends IntegrationConfig = IntegrationConfig> {
  title: string;
  docsUrl?: string;
  description?: string;
  integrationPageText?: string;
  sections?: Array<IntegrationFormSection<T>>;
}

export function getIntegrationForm(
  integrationType: IntegrationTypeWithConfig,
  integration?: IntegrationConfig,
): IntegrationForm {
  const fn = FormMap[integrationType];
  if (!fn) {
    throw new Error('Unsupported integration type');
  }
  return fn(integration);
}

function makeIntegrationIdField(integration?: IntegrationConfig): IntegrationFormField {
  return {
    name: 'Connector ID',
    required: true,
    validator: (id: IntegrationId): string | undefined => {
      if (isValidId(id, 50)) return;
      return 'Use letters, numbers, dashes or underscores. Max length is 50 characters.';
    },
    type: 'regular',
    placeholder: 'Enter connector ID',
    description: 'This should be meaningful and easily readable in code. This cannot be changed later.',
    pathInIntegration: 'id',
    defaultValue: integration?.id,
  };
}

const mysql = <T extends MySqlIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'MySQL',
    description: 'Allow Squid to modify, update and control your MySQL database.',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/mysql',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Database Endpoint',
            description: 'Example: your-mysql-server-host:3306',
            required: true,
            placeholder: 'Enter database endpoint',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.host',
            defaultValue: integration?.configuration?.connectionOptions?.host,
          },
          {
            name: 'Database Username',
            required: true,
            placeholder: 'Enter database username',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.user',
            defaultValue: integration?.configuration?.connectionOptions?.user,
          },
          {
            name: 'Database Password',
            required: true,
            placeholder: 'Select secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
          {
            name: 'Database Name',
            required: true,
            placeholder: 'Enter database name',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.database',
            defaultValue: integration?.configuration?.connectionOptions?.database,
          },
          {
            name: 'SSL Enabled',
            required: false,
            type: 'boolean',
            pathInIntegration: 'configuration.connectionOptions.sslEnabled',
            defaultValue: integration?.configuration?.connectionOptions?.sslEnabled ?? false,
          },
        ],
      },
    ],
  };
};

const clickhouse = <T extends ClickHouseIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'ClickHouse',
    description: 'Allow Squid to modify, update and control your ClickHouse database.',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/clickhouse',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Database URL',
            description: 'Example: https://your-clickhouse-server-host:8123',
            required: true,
            placeholder: 'Enter database URL',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.host',
            defaultValue: integration?.configuration?.connectionOptions?.host,
          },
          {
            name: 'Database Username',
            required: true,
            placeholder: 'Enter database username',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.user',
            defaultValue: integration?.configuration?.connectionOptions?.user,
          },
          {
            name: 'Database Password',
            required: true,
            placeholder: 'Select secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
          {
            name: 'Database Name',
            required: true,
            placeholder: 'Enter database name',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.database',
            defaultValue: integration?.configuration?.connectionOptions?.database,
          },
          {
            name: 'SSL Enabled',
            required: false,
            type: 'boolean',
            pathInIntegration: 'configuration.connectionOptions.sslEnabled',
            defaultValue: integration?.configuration?.connectionOptions?.sslEnabled ?? false,
          },
        ],
      },
    ],
  };
};

const bigquery = <T extends BigQueryIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'BigQuery',
    description: 'Allow Squid to modify, update and control your BigQuery database.',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/bigquery',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Project ID',
            required: true,
            placeholder: 'Enter project ID',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.projectId',
            defaultValue: integration?.configuration?.connectionOptions?.projectId,
          },
          {
            name: 'Service Account Email',
            required: true,
            placeholder: 'Enter the service account email',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.email',
            defaultValue: integration?.configuration?.connectionOptions?.email,
          },
          {
            name: 'Dataset ID',
            required: true,
            placeholder: 'Enter the dataset ID',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.datasetId',
            defaultValue: integration?.configuration?.connectionOptions?.datasetId,
          },
          {
            name: 'Private Key',
            description: 'The private key of the service account',
            required: true,
            placeholder: 'Enter private key',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.privateKey',
            defaultValue: integration?.configuration?.connectionOptions?.secrets.privateKey,
          },
        ],
      },
    ],
  };
};

const oracledb = <T extends OracleIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'Oracle',
    description: 'Allow Squid to modify, update and control your Oracle database.',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/oracle',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Database Endpoint',
            description: 'Example: your-oracle-server-host:1521',
            required: true,
            placeholder: 'Enter database endpoint',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.host',
            defaultValue: integration?.configuration?.connectionOptions?.host,
          },
          {
            name: 'Database Username',
            required: true,
            placeholder: 'Enter database username',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.user',
            defaultValue: integration?.configuration?.connectionOptions?.user,
          },
          {
            name: 'Database Password',
            required: true,
            placeholder: 'Select secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
          {
            name: 'Database Name',
            required: true,
            placeholder: 'Enter database name',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.database',
            defaultValue: integration?.configuration?.connectionOptions?.database,
          },
        ],
      },
    ],
  };
};

const mssql = <T extends MssqlIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'Microsoft SQL Server',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/sqlserver/',
    description: 'Allow Squid to modify, update and control your Microsoft SQL server database.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Database Endpoint',
            description: 'Example: your-mssql-server-host:1433',
            required: true,
            placeholder: 'Enter database endpoint',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.host',
            defaultValue: integration?.configuration?.connectionOptions?.host,
          },
          {
            name: 'Database Username',
            required: true,
            placeholder: 'Enter database username',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.user',
            defaultValue: integration?.configuration?.connectionOptions?.user,
          },
          {
            name: 'Database Password',
            required: true,
            placeholder: 'Select secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
          {
            name: 'Database Name',
            required: true,
            placeholder: 'Enter database name',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.database',
            defaultValue: integration?.configuration?.connectionOptions?.database,
          },
          {
            name: 'Schemas (comma separated)',
            required: false,
            placeholder: 'Enter database schemas',
            description:
              'Name of the schemas to connect to - If none is provided, the default <span class="public_outline">PUBLIC</span> schema will be used',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.schemas',
            defaultValue: integration?.configuration?.connectionOptions?.schemas,
          },
          {
            name: 'SSL Enabled',
            required: false,
            type: 'boolean',
            pathInIntegration: 'configuration.connectionOptions.sslEnabled',
            defaultValue: integration?.configuration?.connectionOptions?.sslEnabled ?? false,
          },
        ],
      },
    ],
  };
};

const postgres = <T extends PostgresIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'PostgreSQL',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/postgres',
    description: 'Allow Squid to modify, update and control your PostgreSQL database.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Database Endpoint',
            description: 'Example: your-postgres-server-host:5432',
            required: true,
            placeholder: 'Enter database endpoint',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.host',
            defaultValue: integration?.configuration?.connectionOptions?.host,
          },
          {
            name: 'Database Username',
            required: true,
            placeholder: 'Enter database username',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.user',
            defaultValue: integration?.configuration?.connectionOptions?.user,
          },
          {
            name: 'Database Password',
            required: true,
            placeholder: 'Select secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
          {
            name: 'Database Name',
            required: true,
            placeholder: 'Enter database name',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.database',
            defaultValue: integration?.configuration?.connectionOptions?.database,
          },
          {
            name: 'Schema',
            required: false,
            placeholder: 'Enter database schema',
            description:
              'Name of the schema to connect to - If none is provided, the default <span class="public_outline">PUBLIC</span> schema will be used',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.schema',
            defaultValue: integration?.configuration?.connectionOptions?.schema,
          },
        ],
      },
      {
        type: 'toggleable',
        pathInIntegration: 'configuration.connectionOptions.sslEnabled',
        title: 'SSL Enabled',
        description: 'Some cloud providers require SSL to connect to PostgreSQL',
        enabled: false,
        fields: [],
      },
    ],
  };
};

const sap_hana = <T extends SapHanaIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'SAP HANA Database',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database',
    description: 'Allow Squid to modify, update, and control your SAP HANA database.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Database Endpoint',
            description: 'Example: your-sap-hana-host:39013',
            required: true,
            placeholder: 'Enter database endpoint',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.host',
            defaultValue: integration?.configuration?.connectionOptions?.host,
          },
          {
            name: 'Database Username',
            required: true,
            placeholder: 'Enter database username',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.user',
            defaultValue: integration?.configuration?.connectionOptions?.user,
          },
          {
            name: 'Database Password',
            required: true,
            placeholder: 'Select secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
          {
            name: 'Database Name',
            required: true,
            placeholder: 'Enter database name',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.database',
            defaultValue: integration?.configuration?.connectionOptions?.database,
          },
          {
            name: 'Schema',
            required: false,
            placeholder: 'Enter database schema',
            description:
              'Name of the schema to connect to - If none is provided, the default schema associated with the connected user will be used',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.schema',
            defaultValue: integration?.configuration?.connectionOptions?.schema,
          },
        ],
      },
      {
        type: 'toggleable',
        pathInIntegration: 'configuration.connectionOptions.sslEnabled',
        title: 'SSL Enabled',
        enabled: false,
        fields: [],
      },
    ],
  };
};

const cockroach = <T extends CockroachIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'CockroachDB',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/cockroachdb',
    description: 'Allow Squid to modify, update and control your CockroachDB database.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Database Endpoint',
            description: 'Example: your-cockroachdb-server-host:26257',
            required: true,
            placeholder: 'Enter database endpoint',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.host',
            defaultValue: integration?.configuration?.connectionOptions?.host,
          },
          {
            name: 'Database Username',
            required: true,
            placeholder: 'Enter database username',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.user',
            defaultValue: integration?.configuration?.connectionOptions?.user,
          },
          {
            name: 'Database Password',
            required: true,
            placeholder: 'Select secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
          {
            name: 'Database Name',
            required: true,
            placeholder: 'Enter database name',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.database',
            defaultValue: integration?.configuration?.connectionOptions?.database,
          },
          {
            name: 'Schema',
            required: false,
            placeholder: 'Enter database schema',
            description:
              'Name of the schema to connect to - If none is provided, the default <span class="public_outline">PUBLIC</span> schema will be used',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.schema',
            defaultValue: integration?.configuration?.connectionOptions?.schema,
          },
        ],
      },
      {
        type: 'toggleable',
        pathInIntegration: 'configuration.connectionOptions.sslEnabled',
        title: 'SSL Enabled',
        description: 'Some cloud providers require SSL to connect to CockroachDB',
        enabled: false,
        fields: [],
      },
    ],
  };
};

const s3 = <T extends S3IntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'AWS S3',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/storage/aws-storage',
    description: 'Allow Squid to store, delete and fetch files from an AWS S3 bucket.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Bucket Name',
            required: true,
            type: 'regular',
            placeholder: 'Enter bucket name',
            pathInIntegration: 'configuration.bucket',
            defaultValue: integration?.configuration?.bucket,
          },
          {
            name: 'Region',
            description: 'Example: us-east-1',
            required: false,
            type: 'regular',
            placeholder: 'Enter bucket region',
            pathInIntegration: 'configuration.region',
            defaultValue: integration?.configuration?.region,
          },

          {
            name: 'Access Key',
            required: false,
            type: 'regular',
            placeholder: 'Enter access key',
            pathInIntegration: 'configuration.accessKey',
            defaultValue: integration?.configuration?.accessKey,
          },
          {
            name: 'Secret Key',
            required: false,
            type: 'secret',
            placeholder: 'Select secret key',
            pathInIntegration: 'configuration.secrets.secretKey',
            defaultValue: integration?.configuration?.secrets?.secretKey,
          },
        ],
      },
    ],
  };
};

const gcs = <T extends GcsIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'Google Cloud Storage',
    description: 'Allow Squid to store, delete and fetch files from a Google Cloud Storage bucket.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Bucket Name',
            required: true,
            type: 'regular',
            placeholder: 'Enter bucket name',
            pathInIntegration: 'configuration.bucket',
            defaultValue: integration?.configuration?.bucket,
          },
          {
            name: 'Credentials JSON String',
            required: false,
            type: 'secret',
            placeholder: 'Credentials JSON',
            pathInIntegration: 'configuration.secrets.credentialsJson',
            defaultValue: integration?.configuration?.secrets?.credentialsJson,
          },
        ],
      },
    ],
  };
};

const google_drive = <T extends GoogleDriveIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'Google Drive',
    description: `Allow Squid to interact with user's Google Drive.`,
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Client ID',
            required: true,
            type: 'regular',
            placeholder: 'Enter client ID',
            pathInIntegration: 'configuration.clientId',
            defaultValue: integration?.configuration?.clientId,
          },
          {
            name: 'Redirect URL',
            required: true,
            type: 'regular',
            placeholder: 'Enter redirect URL',
            validator: (value: string): string | undefined => {
              try {
                new URL(value);
                if (value?.trim().endsWith('/')) return "Redirect URL can't end with a slash";
                return undefined;
              } catch (e) {
                console.error('Error', e);
                return 'Invalid URL format';
              }
            },
            pathInIntegration: 'configuration.redirectUri',
            defaultValue: integration?.configuration?.redirectUri,
          },
          {
            name: 'Client Secret',
            required: false,
            type: 'secret',
            placeholder: 'Client Secret',
            pathInIntegration: 'configuration.secrets.clientSecret',
            defaultValue: integration?.configuration?.secrets?.clientSecret,
          },
        ],
      },
    ],
  };
};

const mongo = <T extends MongoIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'MongoDB',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/mongodb',
    description: 'Allow Squid to modify, update and control your MongoDB database.',
    integrationPageText:
      'See the <a href="https://docs.squid.cloud/docs/tutorials/mongodb" target="_blank"> Mongo integration tutorial</a> for a step-by-step guide on adding a Mongo integration.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Database Connection String',
            description: 'Example: mongodb://your-mongo-server-host:27017/dbName',
            required: true,
            type: 'regular',
            placeholder: 'Enter database connection string',
            pathInIntegration: 'configuration.connectionOptions.connectionString',
            defaultValue: integration?.configuration?.connectionOptions?.connectionString,
          },
          {
            name: 'Database Username',
            required: false,
            type: 'regular',
            placeholder: 'Enter database username',
            pathInIntegration: 'configuration.connectionOptions.user',
            defaultValue: integration?.configuration?.connectionOptions?.user,
          },
          {
            name: 'Database Password',
            required: false,
            type: 'secret',
            placeholder: 'Select secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
        ],
      },
      {
        type: 'toggleable',
        pathInIntegration: 'configuration.connectionOptions.ssl.validate',
        title: 'SSL Enabled',
        description: 'Some cloud providers require SSL to connect to Mongo',
        enabled: false,
        fields: [],
      },
    ],
  };
};

const graphql = <T extends DefaultGraphQLIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'GraphQL',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/api/graphql',
    description: 'Allow Squid to query your GraphQL connection.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Base URL',
            required: true,
            type: 'regular',
            placeholder: 'Enter URL',
            pathInIntegration: 'configuration.connectionOptions.baseUrl',
            defaultValue: integration?.configuration?.connectionOptions?.baseUrl,
          },
          {
            name: 'Inject Request Headers',
            description:
              'Injections can be added to pass headers or query parameters that should not be exposed on the client.',
            required: false,
            type: 'component',
            component: {
              type: 'api-injection',
            },
            pathInIntegration: 'configuration.connectionOptions.injectionSchema',
            defaultValue: integration?.configuration?.connectionOptions?.injectionSchema,
          },
        ],
      },
    ],
  };
};

const httpapi = <T extends DefaultHttpApiIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'API',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/api/httpapi',
    description: 'Allow Squid to connect to your custom API endpoints.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'OpenAPI Specification URL',
            required: false,
            type: 'regular',
            placeholder: 'Enter URL',
            description:
              'To provide schema via file upload, leave this field blank and upload the file in the next step.',
            pathInIntegration: 'configuration.discoveryOptions.openApiSpecUrl',
            defaultValue: integration?.configuration?.discoveryOptions?.openApiSpecUrl,
          },
        ],
      },
    ],
  };
};

const datadog = <T extends DatadogIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'Datadog',
    description: 'Connect your Datadog account to Squid.',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/observability/datadog',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'API Key',
            required: true,
            type: 'secret',
            placeholder: 'Enter API key',
            pathInIntegration: 'configuration.apiKey',
            defaultValue: integration?.configuration?.apiKey,
          },
          {
            name: 'App Key',
            required: true,
            type: 'secret',
            placeholder: 'Enter app key',
            pathInIntegration: 'configuration.appKey',
            defaultValue: integration?.configuration?.appKey,
          },
          {
            name: 'Region',
            required: true,
            type: 'select',
            options: [
              ...Object.entries(datadogRegionMap).map(([value, label]) => {
                return { label, value };
              }),
            ],
            placeholder: 'Enter region',
            pathInIntegration: 'configuration.datadogRegion',
            defaultValue: integration?.configuration?.datadogRegion,
          },
        ],
      },
    ],
  };
};

const newrelic = <T extends NewRelicIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'NewRelic',
    description: 'Connect your NewRelic account to Squid.',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/observability/new-relic',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'API Key',
            required: true,
            type: 'secret',
            placeholder: 'Enter API Key',
            pathInIntegration: 'configuration.apiKey',
            defaultValue: integration?.configuration?.apiKey,
          },
          {
            name: 'Region',
            required: true,
            type: 'select',
            options: [
              ...Object.entries(newRelicRegionMap).map(([value, label]) => {
                return { label, value };
              }),
            ],
            placeholder: 'Enter region',
            pathInIntegration: 'configuration.newRelicRegion',
            defaultValue: integration?.configuration?.newRelicRegion,
          },
        ],
      },
    ],
  };
};

const snowflake = (integration: SnowflakeIntegrationConfig): IntegrationForm<SnowflakeIntegrationConfig> => {
  return {
    title: 'Snowflake',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/database/snowflake',
    description: 'Allow Squid to modify, update and query your Snowflake database.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Snowflake Username',
            required: true,
            type: 'regular',
            placeholder: 'Enter Snowflake username',
            pathInIntegration: 'configuration.connectionOptions.username',
            defaultValue: integration?.configuration?.connectionOptions.username,
          },
          {
            name: 'Snowflake Password',
            required: true,
            type: 'secret',
            placeholder: 'Select secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions.secrets.password,
          },
          {
            name: 'Snowflake Account',
            required: true,
            type: 'regular',
            placeholder: 'Enter Snowflake account',
            pathInIntegration: 'configuration.connectionOptions.account',
            defaultValue: integration?.configuration?.connectionOptions.account,
          },
          {
            name: 'Snowflake Database',
            required: true,
            type: 'regular',
            placeholder: 'Enter Snowflake database',
            pathInIntegration: 'configuration.connectionOptions.database',
            defaultValue: integration?.configuration?.connectionOptions.database,
          },
          {
            name: 'Snowflake Schema',
            required: true,
            type: 'regular',
            placeholder: 'Enter Snowflake schema',
            pathInIntegration: 'configuration.connectionOptions.schema',
            defaultValue: integration?.configuration?.connectionOptions.schema,
          },
          {
            name: 'Snowflake Warehouse',
            required: true,
            type: 'regular',
            placeholder: 'Enter Snowflake warehouse',
            pathInIntegration: 'configuration.connectionOptions.warehouse',
            defaultValue: integration?.configuration?.connectionOptions.warehouse,
          },
          {
            name: 'Snowflake Role',
            required: true,
            type: 'regular',
            placeholder: 'Enter Snowflake role',
            pathInIntegration: 'configuration.connectionOptions.role',
            defaultValue: integration?.configuration?.connectionOptions.role,
          },
        ],
      },
    ],
  };
};

const auth0 = (integration: Auth0IntegrationConfig): IntegrationForm<Auth0IntegrationConfig> => {
  return {
    title: 'Auth0',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/auth/auth0',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Domain',
            required: true,
            type: 'regular',
            placeholder: 'Enter Auth0 domain',
            pathInIntegration: 'configuration.domain',
            defaultValue: integration?.configuration.domain,
          },
          {
            name: 'Audience',
            required: true,
            type: 'regular',
            placeholder: 'Enter Auth0 audience',
            pathInIntegration: 'configuration.clientId',
            defaultValue: integration?.configuration.clientId,
          },
        ],
      },
    ],
  };
};

const jwt_rsa = (integration: JwtRsaIntegrationConfig): IntegrationForm<JwtRsaIntegrationConfig> => {
  return {
    title: 'JWT RSA Auth',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/auth/jwt_rsa',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'JWKs URI',
            description: 'The endpoint to retrieve the JSON Web Key Set used for verifying the RSA-signed JWT',
            required: true,
            type: 'regular',
            placeholder: 'Enter JWKs URI',
            pathInIntegration: 'configuration.jwksUri',
            defaultValue: integration?.configuration.jwksUri,
          },
        ],
      },
      {
        type: 'toggleable',
        title: 'Validations',
        description: 'Specify optional JWT claims validation rules such as issuer and audience',
        enabled: !!integration?.configuration.validations,
        fields: [
          {
            name: 'Issuer',
            description: 'The expected iss claim value in the JWT',
            required: false,
            type: 'regular',
            placeholder: 'Enter issuer',
            pathInIntegration: 'configuration.validations.iss',
            defaultValue: integration?.configuration.validations?.iss,
          },
          {
            name: 'Audience',
            description: 'The expected aud claim value in the JWT',
            required: false,
            type: 'regular',
            placeholder: 'Enter audience',
            pathInIntegration: 'configuration.validations.aud',
            defaultValue: integration?.configuration.validations?.aud,
          },
        ],
      },
    ],
  };
};

const jwt_hmac = (integration: JwtHmacIntegrationConfig): IntegrationForm<JwtHmacIntegrationConfig> => {
  return {
    title: 'JWT HMAC Auth',
    docsUrl: 'https://docs.squid.cloud/docs/getting-started/feature-summary/#jwt-hmac-auth',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Verification URL',
            description: 'Endpoint to validate the HMAC signed JWT and retrieve the JWT payload.',
            required: true,
            type: 'regular',
            placeholder: 'Enter verification URL',
            pathInIntegration: 'configuration.verifyUri',
            defaultValue: integration?.configuration.verifyUri,
          },
        ],
      },
    ],
  };
};

const ai_chatbot = (integration: AiChatbotIntegrationConfig): IntegrationForm<AiChatbotIntegrationConfig> => {
  return {
    title: 'AI Chatbot',
    docsUrl: 'https://docs.squid.cloud/docs/ai/ai-chatbot',
    sections: [
      {
        type: 'default',
        fields: [makeIntegrationIdField(integration)],
      },
    ],
  };
};

const cognito = (integration: CognitoIntegrationConfig): IntegrationForm<CognitoIntegrationConfig> => {
  return {
    title: 'Cognito',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/auth/cognito',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Region',
            required: true,
            type: 'regular',
            placeholder: 'Enter AWS region',
            pathInIntegration: 'configuration.region',
            defaultValue: integration?.configuration.region,
          },
          {
            name: 'User Pool ID',
            required: true,
            type: 'regular',
            placeholder: 'Enter user pool ID',
            pathInIntegration: 'configuration.userPoolId',
            defaultValue: integration?.configuration.userPoolId,
          },
          {
            name: 'App Client ID',
            required: true,
            type: 'regular',
            placeholder: 'Enter app client ID',
            pathInIntegration: 'configuration.appClientId',
            defaultValue: integration?.configuration.appClientId,
          },
        ],
      },
    ],
  };
};

const okta = (integration: OktaIntegrationConfig): IntegrationForm<OktaIntegrationConfig> => {
  return {
    title: 'Okta',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/auth/okta',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Domain',
            required: true,
            type: 'regular',
            placeholder: 'Enter Okta domain',
            pathInIntegration: 'configuration.domain',
            defaultValue: integration?.configuration.domain,
          },
          {
            name: 'Client ID',
            required: true,
            type: 'regular',
            placeholder: 'Enter client ID',
            pathInIntegration: 'configuration.clientId',
            defaultValue: integration?.configuration.clientId,
          },
        ],
      },
    ],
  };
};

const kafkaAvroSchemaSection = <T extends KafkaIntegrationConfig>(
  integration: T,
): IntegrationFormSection<KafkaIntegrationConfig> => {
  return {
    type: 'toggleable',
    title: 'Enable Avro schema registry',
    description: 'Register your Avro schema to support the right serialization and deserialization of messages',
    enabled: false,
    fields: [
      {
        name: 'Schema Registry URL',
        required: true,
        type: 'regular',
        placeholder: 'URL',
        prefixIcon: 'link_icon',
        pathInIntegration: 'configuration.connectionOptions.schemaRegistryUrl',
        defaultValue: integration?.configuration?.connectionOptions?.schemaRegistryUrl,
      },
      {
        name: 'Schema Registry Username',
        required: true,
        type: 'regular',
        placeholder: 'Username',
        pathInIntegration: 'configuration.connectionOptions.schemaRegistryUsername',
        defaultValue: integration?.configuration?.connectionOptions?.schemaRegistryUsername,
      },
      {
        name: 'Schema Registry Password',
        required: true,
        type: 'secret',
        placeholder: 'Password',
        pathInIntegration: 'configuration.connectionOptions.secrets.schemaRegistryPassword',
        defaultValue: integration?.configuration?.connectionOptions?.secrets?.schemaRegistryPassword,
      },
    ],
  };
};

const kafka = <T extends KafkaIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'Kafka',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/queue/kafka',
    description: 'Allow Squid to consume and publish messages on your Kafka topics.',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Bootstrap Servers',
            required: true,
            description:
              'Enter a comma separated list of servers and ports. <br />Example: <b>broker1:9092,broker2:9093</b>',
            placeholder: 'Enter bootstrap servers',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.bootstrapServers',
            defaultValue: integration?.configuration?.connectionOptions?.bootstrapServers,
          },
          {
            name: 'Key',
            required: false,
            placeholder: 'Kafka API key',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.username',
            defaultValue: integration?.configuration?.connectionOptions?.username,
          },
          {
            name: 'Secret',
            required: false,
            placeholder: 'Kafka API secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
        ],
      },
      kafkaAvroSchemaSection(integration),
    ],
  };
};

const confluent = <T extends KafkaIntegrationConfig>(integration: T): IntegrationForm<T> => {
  return {
    title: 'Confluent',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/queue/confluent',
    description:
      'Allow Squid to consume and publish messages on your Kafka topics. <a href="https://www.confluent.io/confluent-cloud/tryfree/?utm_campaign=tm.pmm_cd.cwc_partner_squid_tryfree&utm_source=squid&utm_medium=partnerref" target="_blank">Confluent Cloud Trial</a>',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Bootstrap Servers',
            required: true,
            description:
              'Enter a comma separated list of servers and ports. <br />Example: <b>broker1:9092,broker2:9093</b>',
            placeholder: 'Enter bootstrap servers',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.bootstrapServers',
            defaultValue: integration?.configuration?.connectionOptions?.bootstrapServers,
          },
          {
            name: 'Key',
            required: false,
            placeholder: 'Kafka API key',
            type: 'regular',
            pathInIntegration: 'configuration.connectionOptions.username',
            defaultValue: integration?.configuration?.connectionOptions?.username,
          },
          {
            name: 'Secret',
            required: false,
            placeholder: 'Kafka API secret',
            type: 'secret',
            pathInIntegration: 'configuration.connectionOptions.secrets.password',
            defaultValue: integration?.configuration?.connectionOptions?.secrets?.password,
          },
        ],
      },
      kafkaAvroSchemaSection(integration),
    ],
  };
};

const descope = (integration: DescopeIntegrationConfig): IntegrationForm<DescopeIntegrationConfig> => {
  return {
    title: 'Descope',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/auth/descope',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Project ID',
            required: true,
            type: 'regular',
            placeholder: 'Enter Descope project ID',
            pathInIntegration: 'configuration.projectId',
            defaultValue: integration?.configuration.projectId,
          },
        ],
      },
    ],
  };
};

const firebase_auth = (integration: FirebaseAuthIntegrationConfig): IntegrationForm<FirebaseAuthIntegrationConfig> => {
  return {
    title: 'Firebase Auth',
    docsUrl: 'https://docs.squid.cloud/docs/integrations/auth/firebase',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Project ID',
            required: true,
            type: 'regular',
            placeholder: 'Enter Firebase project ID',
            pathInIntegration: 'configuration.projectId',
            defaultValue: integration?.configuration.projectId,
          },
        ],
      },
    ],
  };
};

const linear = (integration: LinearIntegrationConfig): IntegrationForm<LinearIntegrationConfig> => {
  return {
    title: 'Linear',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'API Key',
            required: true,
            type: 'secret',
            placeholder: 'Enter Linear API key',
            pathInIntegration: 'configuration.secrets.apiKey',
            defaultValue: integration?.configuration.secrets.apiKey,
          },
        ],
      },
    ],
  };
};

const confluence = (integration: ConfluenceIntegrationConfig): IntegrationForm<ConfluenceIntegrationConfig> => {
  return {
    title: 'Confluence',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Domain',
            required: true,
            type: 'regular',
            placeholder: 'Enter Confluence domain',
            pathInIntegration: 'configuration.domain',
            defaultValue: integration?.configuration.domain,
          },
          {
            name: 'Email',
            required: true,
            type: 'regular',
            placeholder: 'Enter Confluence email',
            pathInIntegration: 'configuration.email',
            defaultValue: integration?.configuration.email,
          },
          {
            name: 'API Key',
            required: true,
            type: 'regular',
            placeholder: 'Enter Confluence API key',
            pathInIntegration: 'configuration.apiKey',
            defaultValue: integration?.configuration.apiKey,
          },
        ],
      },
    ],
  };
};

const servicenow = (integration: ServiceNowIntegrationConfig): IntegrationForm<ServiceNowIntegrationConfig> => {
  return {
    title: 'ServiceNow',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Domain',
            required: true,
            type: 'regular',
            placeholder: 'Enter ServiceNow instance domain',
            pathInIntegration: 'configuration.domain',
            defaultValue: integration?.configuration.domain,
            description: 'The domain of your ServiceNow instance, e.g., "example.service-now.com".',
          },
        ],
      },
      {
        type: 'toggleable',
        title: 'Single User Mode',
        enabled: false,
        description:
          'If enabled, the AI functions can use the provided user credentials to make API calls to ServiceNow.',
        pathInIntegration: 'configuration.isSingleUserMode',
        saveToggleStateToIntegrationConfig: true,
        fields: [
          {
            name: 'Username',
            required: true,
            defaultValue: integration?.configuration.username,
            type: 'regular',
            pathInIntegration: 'configuration.username',
            description: 'User to be used in single-user mode to authenticate requests to ServiceNow.',
            placeholder: 'Enter username',
          },
          {
            name: 'Password',
            required: true,
            defaultValue: integration?.configuration.passwordKey,
            type: 'secret',
            pathInIntegration: 'configuration.passwordKey',
            description: 'Password to be used in single-user mode to authenticate requests to ServiceNow.',
            placeholder: 'Password secret key',
          },
        ],
      },
    ],
  };
};

const salesforce_crm = (
  integration: SalesforceCrmIntegrationConfig,
): IntegrationForm<SalesforceCrmIntegrationConfig> => {
  return {
    title: 'SalesforceCrm',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Domain',
            required: true,
            type: 'regular',
            validator: (value: string): string | undefined => {
              try {
                if (!/^https:\/\//.test(value)) {
                  return 'Domain must start with https://';
                }
                new URL(value);
                if (value?.trim().endsWith('/')) return "Domain can't end with a slash";
                return undefined;
              } catch (e) {
                console.error('Error', e);
                return 'Invalid URL format';
              }
            },
            placeholder: 'Enter Salesforce instance domain',
            pathInIntegration: 'configuration.domain',
            defaultValue: integration?.configuration.domain,
            description: 'The domain of your Salesforce instance, e.g., "https://domain-name-1234.my.salesforce.com".',
          },
          {
            name: 'Consumer Key',
            required: true,
            type: 'regular',
            placeholder: 'Consumer Key',
            pathInIntegration: 'configuration.consumerKey',
            defaultValue: integration?.configuration.consumerKey,
          },
          {
            name: 'Consumer Secret',
            required: true,
            type: 'secret',
            placeholder: 'Consumer Secret',
            pathInIntegration: 'configuration.consumerSecret',
            defaultValue: integration?.configuration.consumerSecret,
          },
        ],
      },
    ],
  };
};

const jira = (integration: JiraIntegrationConfig): IntegrationForm<JiraIntegrationConfig> => {
  return {
    title: 'Jira',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Domain',
            required: true,
            type: 'regular',
            placeholder: 'Enter Jira domain name',
            pathInIntegration: 'configuration.domain',
            defaultValue: integration?.configuration.domain,
            description: 'The domain name of your Jira instance.',
          },
          {
            name: 'API Key',
            required: true,
            type: 'secret',
            placeholder: 'Enter API Key',
            pathInIntegration: 'configuration.apiKey',
            defaultValue: integration?.configuration.apiKey,
            description:
              'Your Atlassian API Key. For more information, visit the <a href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/" target="_blank">documentation</a>',
          },
          {
            name: 'Email',
            required: true,
            type: 'regular',
            placeholder: 'Enter email',
            pathInIntegration: 'configuration.email',
            defaultValue: integration?.configuration.email,
            description: 'Your email associated with the API key.',
          },
        ],
      },
    ],
  };
};

const zendesk = (integration: ZendeskIntegrationConfig): IntegrationForm<ZendeskIntegrationConfig> => {
  return {
    title: 'Zendesk',
    sections: [
      {
        type: 'default',
        fields: [
          makeIntegrationIdField(integration),
          {
            name: 'Domain',
            required: true,
            type: 'regular',
            placeholder: 'Enter Zendesk instance domain',
            validator: (value: string): string | undefined => {
              try {
                const trimmed = value?.trim();
                if (trimmed.includes('://')) {
                  return 'Do not include the protocol (http/https) in the domain.';
                }
                if (trimmed.endsWith('/') || trimmed.endsWith('?')) {
                  return "Don't end the domain with any special characters.";
                }
                new URL('https://' + trimmed);
                return undefined;
              } catch (e) {
                console.error('Error', e);
                return 'Invalid URL format';
              }
            },
            pathInIntegration: 'configuration.domain',
            defaultValue: integration?.configuration.domain,
            description: 'The domain of your Zendesk instance, e.g., "example.zendesk.com".',
          },
          {
            name: 'Email',
            required: true,
            type: 'regular',
            placeholder: 'user@example.com',
            pathInIntegration: 'configuration.email',
            defaultValue: integration?.configuration.email,
            description: 'The email address of the account to log in to Zendesk.',
          },
          {
            name: 'API Token',
            required: true,
            type: 'secret',
            placeholder: 'Token',
            pathInIntegration: 'configuration.apiToken',
            defaultValue: integration?.configuration.apiToken,
            description:
              'The token to access your instance\'s Zendesk. Can be found at "https://YOUR_DOMAIN/admin/apps-integrations/apis/zendesk-api/settings/tokens/".',
          },
        ],
      },
    ],
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormMap: Record<IntegrationTypeWithConfig, ((integration: any) => IntegrationForm<any>) | undefined> = {
  ai_agents: undefined,
  ai_chatbot,
  api: httpapi,
  auth0,
  bigquery,
  built_in_db: undefined,
  built_in_gcs: undefined,
  built_in_queue: undefined,
  built_in_s3: undefined,
  clickhouse,
  cockroach,
  cognito,
  confluence,
  confluent,
  datadog,
  descope,
  firebase_auth,
  gcs,
  google_drive,
  graphql,
  jira,
  jwt_hmac,
  jwt_rsa,
  kafka,
  linear,
  mongo,
  mssql,
  mysql,
  newrelic,
  okta,
  oracledb,
  postgres,
  s3,
  salesforce_crm,
  sap_hana,
  servicenow,
  snowflake,
  zendesk,
};

export function isComponentIntegrationFormField(
  field: IntegrationFormField,
): field is ComponentIntegrationFormField<IntegrationConfig> {
  return field.type === 'component';
}

export function isInputIntegrationFormField(
  field: IntegrationFormField,
): field is InputIntegrationFormField<IntegrationConfig> {
  return field.type === 'regular';
}

export function isSelectIntegrationFormField(
  field: IntegrationFormField,
): field is SelectIntegrationFormField<IntegrationConfig> {
  return field.type === 'select';
}

export function isBooleanIntegrationFormField(
  field: IntegrationFormField,
): field is BooleanIntegrationFormField<IntegrationConfig> {
  return field.type === 'boolean';
}

export function isSecretIntegrationFormField(
  field: IntegrationFormField,
): field is SecretIntegrationFormField<IntegrationConfig> {
  return field.type === 'secret';
}
