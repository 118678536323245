<div class="action_card knowledge_card" [ngClass]="{border, warning}">
  <div class="card_wrapper">
    <mat-icon *ngIf="rudder" class="rudder" [svgIcon]="'info_rudder_' + rudder"></mat-icon>
    <div class="text_wrapper">
      <div class="title">{{title}}</div>
      <div class="description">{{description}}</div>
    </div>
    <div class="cta">
      <button (click)="action.emit()"
              [disabled]="disabled"
              [title]="tooltip || cta"
              [ngClass]="warning ? 'warning_button' : 'primary_button'"
              class="large_button" mat-flat-button>
        {{cta}}
      </button>
    </div>
  </div>
</div>
