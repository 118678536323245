import { Injectable } from '@angular/core';
import { IntegrationId, Squid } from '@squidcloud/client';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, firstValueFrom, map, Observable } from 'rxjs';
import { AccountService } from '../account/account.service';
import { ApplicationService } from '../application/application.service';
import { OrganizationService } from '../organization/organization.service';
import {
  CpApplication,
  CpIntegration,
  RequestForIntegration,
} from '@squidcloud/console-common/types/application.types';
import { generateId } from '@squidcloud/internal-common/public-utils/id-utils';
import { UpsertIntegrationRequest } from '@squidcloud/internal-common/types/application.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { waitForAsyncUpdate } from '@squidcloud/console-web/app/utils/squid-utils';
import { assertTruthy } from 'assertic';
import { getApplicationUrl } from '@squidcloud/internal-common/utils/http';
import { IntegrationConfigTypes, IntegrationSchemaTypes } from '@squidcloud/internal-common/types/integrations/schemas';
import { serializeObjForBashCommand } from '@squidcloud/console-web/app/utils/serialization';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  private allIntegrationsSubject = new BehaviorSubject<Array<CpIntegration>>([]);

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    private readonly organizationService: OrganizationService,
    private readonly squid: Squid,
    private readonly accountService: AccountService,
  ) {
    this.observeIntegrations().subscribe(this.allIntegrationsSubject);
  }

  get currentApplication(): CpApplication {
    return this.applicationService.getCurrentApplicationOrFail();
  }

  async getIntegration<T extends CpIntegration>(integrationId: IntegrationId): Promise<T | undefined> {
    const integrations = await firstValueFrom(this.observeIntegrations());
    return integrations.find(i => i.id === integrationId) as T;
  }

  getAllIntegrations(): Array<CpIntegration> {
    return this.allIntegrationsSubject.value;
  }

  /** Emits a new value when any of the  integrations or the context (application) changes. */
  observeIntegrations(): Observable<Array<CpIntegration>> {
    return this.applicationService.observeCurrentApplication().pipe(
      filter(Boolean),
      distinctUntilChanged(
        (appBefore, appAfter) =>
          appBefore?.appId === appAfter?.appId &&
          JSON.stringify(appBefore?.integrations) === JSON.stringify(appAfter.integrations),
      ),
      map(application => Object.values(application.integrations)),
    );
  }

  /** Emits a new value the integration or the context (application) changes. */
  observeIntegration<T extends CpIntegration>(integrationId: IntegrationId): Observable<T> {
    return combineLatest([this.observeIntegrations(), this.applicationService.observeCurrentApplication()]).pipe(
      map(([integrations, app]) => ({
        integration: integrations.find(integration => integration.id === integrationId) as T,
        appId: app?.appId,
      })),
      filter(({ integration }) => Boolean(integration)),
      distinctUntilChanged(
        (prev, curr) =>
          prev.appId === curr.appId && JSON.stringify(prev.integration) === JSON.stringify(curr.integration),
      ),
      map(({ integration }) => integration),
    );
  }

  async upsertIntegration(request: UpsertIntegrationRequest): Promise<void> {
    const application = this.applicationService.getCurrentApplicationOrFail();
    await callBackendExecutable(this.squid, 'upsertIntegration', {
      organizationId: application.organizationId,
      applicationId: application.appId,
      upsert: request,
    });
    await waitForAsyncUpdate(this.observeIntegration(request.id), 'upsertIntegration');
  }

  async deleteIntegration(integrationId: IntegrationId): Promise<void> {
    const app = this.applicationService.getCurrentApplicationOrFail();
    await callBackendExecutable(this.squid, 'deleteIntegration', {
      organizationId: app.organizationId,
      applicationId: app.appId,
      integrationId,
    });
  }

  async submitRequestForIntegration(name: string, link: string, description: string): Promise<void> {
    const user = this.accountService.getUserOrFail();
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const application = this.applicationService.getCurrentApplicationOrFail();

    const id = generateId();
    const request: RequestForIntegration = {
      id,
      appId: application.appId,
      email: user.email,
      userId: user.id,
      organizationId: organization.id,
      name,
      link,
      description,
    };

    return await this.squid.collection('requestForIntegration').doc(id).insert(request);
  }

  async getIntegrationCreationScript(integrationId: IntegrationId): Promise<string> {
    const appId = this.applicationService.getCurrentApplication()?.appId;
    assertTruthy(appId, 'No application is selected');
    const appApiKey = await this.applicationService.getApiKey();
    const { body: integration } = await this.squid
      .api()
      .get<IntegrationConfigTypes[keyof IntegrationConfigTypes]>('consoleIac', 'GetIntegration', {
        pathParams: {
          integrationId,
          appId,
        },
        headers: { 'x-app-api-key': appApiKey },
      });
    const baseUrl = getApplicationUrl(this.squid.options.region, this.squid.options.appId, 'openapi');
    delete integration.creationDate;
    delete integration.updateDate;
    const scriptForIntegration = `curl -X PUT ${baseUrl}/iac/applications/${appId}/integrations/${integrationId} -H "x-app-api-key: ${appApiKey}" -H "Content-Type: application/json" -d '${serializeObjForBashCommand(
      integration,
    )}'`;

    const { body: schema } = await this.squid
      .api()
      .get<IntegrationSchemaTypes[keyof IntegrationSchemaTypes] | null>('consoleIac', 'GetIntegrationSchema', {
        pathParams: {
          integrationId,
          appId,
        },
        headers: { 'x-app-api-key': appApiKey },
      });
    if (!schema) {
      return scriptForIntegration;
    }
    const scriptForSchema = `curl -X PUT ${baseUrl}/iac/applications/${appId}/integrations/${integrationId}/schema -H "x-app-api-key: ${appApiKey}" -H "Content-Type: application/json" -d '${serializeObjForBashCommand(
      schema,
    )}'`;
    return `${scriptForIntegration}\n\n\n${scriptForSchema}`;
  }

  showRequestIntegrationDialog(name?: string): void {
    this.globalUiService
      .showDialogWithForm<{ name: string; link: string; description: string }>({
        title: 'Request Connector',
        submitButtonText: 'Request',
        textLines: [
          'Please let us know what integration you would like added and a brief description of how you’d like to use it. ',
        ],
        autoFocus: true,
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'name',
            inputType: 'text',
            label: 'Name of connector',
            defaultValue: name,
          },
          {
            type: 'input',
            required: true,
            nameInForm: 'link',
            inputType: 'text',
            label: 'Link to connector',
          },
          {
            type: 'input',
            required: true,
            nameInForm: 'description',
            inputType: 'text',
            label: 'Brief description',
          },
        ],
        onSubmit: async data => {
          const { name, link, description } = data;
          try {
            await this.submitRequestForIntegration(name, link, description);
            this.snackBar.success('Request submitted');
          } catch (e) {
            this.snackBar.warning(`Unable to submit request, ${(e as Error).message}`);
          }
        },
      })
      .then();
  }
}
