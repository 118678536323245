<div class="onboarding_content">
<div class="onboarding_application">
  <h1 class="header">Name your app</h1>
  <div class="text">
    An application is your workspace on the Squid AI platform and where you’ll build and manage your AI agents using Squid’s built-in features and security capabilities. You can customize the name below or click next to continue.
  </div>

  <create-application-form (formSubmitted)="submitCreateApplication()"></create-application-form>
  <button class="primary_button extra_large_button next_button" mat-flat-button type="submit"
          (click)="submitCreateApplication()"
          [disabled]="!createApplicationFormComponent?.form?.valid || submitCreateApplicationInProgress"
          data-testid="onboarding-next-button"
  >
    <loading-indicator-overlay [isLoading]="submitCreateApplicationInProgress">Next</loading-indicator-overlay>
  </button>
</div>
</div>


