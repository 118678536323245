import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IntegrationId, SquidRegion } from '@squidcloud/client';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApplicationService } from '../../../../application/application.service';
import { filter } from 'rxjs';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { environment } from '@squidcloud/console-web/environments/environment';

export interface EmbedWidgetDialogData {
  integrationId: IntegrationId;
  agentId?: string;
}

@Component({
  templateUrl: './embed-widget-dialog.component.html',
  styleUrls: ['./embed-widget-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EmbedWidgetDialogComponent {
  readonly appObs;

  constructor(
    private readonly applicationService: ApplicationService,
    @Inject(MAT_DIALOG_DATA) public data: EmbedWidgetDialogData,
  ) {
    this.appObs = this.applicationService.observeCurrentApplication().pipe(filter(Boolean));
  }

  static show(dialog: MatDialog, data: EmbedWidgetDialogData): void {
    dialog.open(EmbedWidgetDialogComponent, {
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data,
    });
  }

  getIntegrationId(): IntegrationId | undefined {
    return this.data.integrationId === 'ai_agents' ? undefined : this.data.integrationId;
  }

  getRegion(app: CpApplication): SquidRegion {
    return convertToSquidRegion(app.cloudId, app.region, app.shard, environment.stage);
  }
}
