export const CONTACT_LIST_COLLECTION = 'contactList';

export type CpContact = {
  email: string;
  name?: string;
  phone?: string;
  message?: string;
  referralSource?: string;
  captcha: string;
  location?: ContactLocation;
  reason?: ContactReason;
  submissionDate: Date;
};

export type ContactReason = 'demo' | 'contact_developers' | 'billing_or_quota';

export type ContactLocation = 'ai-chat' | 'welcome' | 'contact-page' | 'contact-us-dialog' | 'registration-page';

export const ZOOM_CLIENT_ID = 'UDgloxf6TkuMG3YkR1y2Uw';

// TODO: remove Zoom reviewer's email
export const ZOOM_EMAILS = [
  'michelle@squid.cloud',
  'etai@squid.cloud',
  'catalina.user1@zoomappsec.us',
  'christopher.laylo@zoomappsec.us',
];
