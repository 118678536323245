import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AGENT_ID_PARAMETER, getRequiredPageParameter } from '@squidcloud/console-web/app/utils/http-utils';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrl: './audit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AuditComponent {
  readonly agentId: string;

  constructor(activatedRoute: ActivatedRoute) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
  }
}
