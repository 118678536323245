import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';

import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { Observable } from 'rxjs';
import { AiChatbotProfiles } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getSortedKeys } from '@squidcloud/console-web/app/utils/angular-utils';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import {
  AbilityType,
  getAbilityMetadata,
  isAbilityType,
} from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StudioHomeComponent {
  protected readonly getSortedKeys = getSortedKeys;

  readonly EDUCATION = {
    title: 'Creating AI Agent in Three Steps...',
    steps: [
      {
        number: '01',
        text: 'Give your agent a name and a description',
      },
      {
        number: '02',
        text: 'Provide it with instructions and abilities',
      },
      {
        number: '03',
        text: 'Test and deploy your agent!',
      },
    ],
  };

  readonly agents$: Observable<AiChatbotProfiles | undefined>;
  readonly application$ = this.applicationService.currentApplication$;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    private readonly router: Router,
  ) {
    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(() => {
      void this.studioService.initialize();
    });
    this.agents$ = this.studioService.observeAgents();
  }

  get hasAgents(): boolean {
    const schema = this.studioService.getSchemaOrFail();
    return !!Object.keys(schema.profiles).length;
  }

  getTopAbilities(agentId: string): { top: Array<AbilityType>; count: number } {
    const agent = this.studioService.getAgentSchemaOrFail(agentId);
    const abilitiesType = [
      ...(agent.connectedIntegrations || []).map(i => i.integrationType),
      ...Object.values(agent.contexts).map(c => c.type || 'text'),
      ...(agent.connectedAgents || []).map(() => 'connected_agent'),
      ...(agent.functions || []).map(() => 'ai_function'),
    ].filter(isAbilityType);

    if (abilitiesType.length > 3) {
      return {
        top: abilitiesType.slice(0, 2),
        count: abilitiesType.length - 2,
      };
    } else {
      return {
        top: abilitiesType,
        count: 0,
      };
    }
  }

  getImage(type: AbilityType): string {
    return `/assets/integrations/${getAbilityMetadata(type).icon}.svg`;
  }

  createAgent(): void {
    this.globalUiService
      .showDialogWithForm<{ id: string; description: string }>({
        title: `Add agent?`,
        minRole: 'ADMIN',
        textLines: ['Agents allow you to inject context and instructions into your AI model prompts'],
        submitButtonText: 'Add',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'id',
            label: 'Agent ID',
          },
          {
            type: 'textarea',
            required: true,
            attributes: {
              autosize: true,
              minRows: 10,
              maxRows: 15,
            },
            nameInForm: 'description',
            label: 'Description',
          },
        ],
        onSubmit: async (data): Promise<string | void> => {
          if (!data) return;
          const { id: agentId, description } = data;

          try {
            const schema = this.studioService.getSchemaOrFail();
            if (schema.profiles[agentId]) {
              throw new Error('An agent with this id already exists');
            }

            await this.studioService.addAgent(agentId, {
              modelName: 'gpt-4o',
              description,
              isPublic: false,
              auditLog: false,
            });
            const application = this.applicationService.getCurrentApplicationOrFail();
            void this.router.navigate(['/application', application.appId, 'studio', agentId, 'create']);
            this.snackBar.success('Agent created');
          } catch (error: unknown) {
            const message = error instanceof Error ? error.message : 'Unable to create agent';
            this.snackBar.warning(message);
          }
        },
      })
      .then();
  }
}
