@if (aiTestChatFlyOutService.widgetParams$ | async; as widgetParams) {
  @let testChatActive = aiTestChatFlyOutService.testChatIsActive$ | async;
  <div class="test_chat_container"
       [ngClass]="{ active: testChatActive, ai_query: widgetParams.aiQuery }"
  >
    <div class="header">
      <div class="title">
        {{
          widgetParams.aiQuery
            ? 'Query ' + widgetParams.integrationId
            : 'Test chat'
        }}
      </div>
      <mat-icon
        class="close_button"
        svgIcon="close_icon"
        (click)="aiTestChatFlyOutService.closeTestChat()"
        data-testid="close-test-chat-button"
      ></mat-icon>
      @if (widgetParams.aiQuery) {
        <div class="explanation_text">
          For better results, be sure to add descriptions to your collections and
          relevant fields
        </div>
      } @else {
        <div class="disable_history_toggle_container"
             matTooltip="Disables history for the chatbot, so each question is answered as if it were the first question in the conversation.">
          Use history
          <mat-slide-toggle
            [(ngModel)]="historyEnabled"
            class="section_toggle_button"
            color="primary"
          >
          </mat-slide-toggle>
        </div>
      }
    </div>
    <div class="widget_container">
      <!-- @for loop forces Angular to re-create the component every time appId, integrationId, or profileId is changed. -->
      @for (updateKey of [widgetParams.appId + '/' + widgetParams.integrationId + '/' + widgetParams.profileId]; track updateKey) {
        <!--suppress AngularUndefinedBinding -->
        <squid-chat-widget
          class="squid_chat_widget"
          [intro-text]="widgetParams.introText"
          [header-title]="[widgetParams.profileId || 'SquidAI Chat']"
          [squid-app-id]="widgetParams.appId"
          [squid-region]="widgetParams.region"
          [squid-environment-id]="widgetParams.environmentId"
          [squid-api-key]="widgetParams.apiKey"
          [squid-ai-integration-id]="widgetParams.integrationId"
          [squid-ai-agent-id]="widgetParams.profileId"
          [squid-ai-query]="widgetParams.aiQuery"
          [enable-transcription]="true"
          [disable-history]="!historyEnabled"
          [include-reference]="true"
          (change)="onChange($event)"
        >
        </squid-chat-widget>
      }
    </div>
  </div>
}
