import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { debounceTime, Observable, take } from 'rxjs';
import { AiChatbotProfile } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import {
  AGENT_ID_PARAMETER,
  getQueryParameter,
  getRequiredPageParameter,
  INTEGRATION_ID_PARAMETER,
} from '@squidcloud/console-web/app/utils/http-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AiAgentId, AiChatbotContextMetadata, AiChatModelName, AiProfileId, IntegrationType } from '@squidcloud/client';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { AbilityConstructorMap, AbilityFactory } from '@squidcloud/console-web/app/studio/agent/abilities/factory';
import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { AiFunctionMetadata } from '@squidcloud/internal-common/types/bundle-data.types';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';
import { getEntries } from '@squidcloud/console-web/app/utils/angular-utils';
import { PortalService } from '@squidcloud/console-web/app/global/portal/portal.service';
import { IntegrationService } from '@squidcloud/console-web/app/integrations/integration.service';
import { Categories, DefaultIntegrations, Integrations } from '@squidcloud/console-web/app/integrations/utils/content';
import {
  AbilityConnectOptionsMap,
  AbilityCreateOptionsMap,
  AbilityEditOptionsMap,
  AbilityType,
  AbilityTypeWithCreate,
  getAbilityType,
  isAbilityType,
  KnowledgeAbilityType,
} from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import { IntegrationCategory } from '@squidcloud/console-common/types/integration.types';
import { groupBy } from '@squidcloud/internal-common/utils/object';
import { truthy } from 'assertic';
import { AiConnectedIntegrationMetadata } from '@squidcloud/internal-common/types/ai-chatbot.types';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AbilityFactory],
  standalone: false,
})
export class StudioCreateComponent implements OnInit, OnDestroy {
  readonly agentId: string;
  readonly agent$: Observable<AiChatbotProfile | undefined>;
  readonly application$ = this.applicationService.currentApplication$;

  @ViewChild('studioFlyout', { static: true }) studioFlyoutRef!: TemplateRef<unknown>;

  readonly getEntries = getEntries;
  readonly isAbilityType = isAbilityType;
  readonly getAbilityType = getAbilityType;

  ModelNameMap: Record<AiChatModelName, string> = {
    'gpt-4o': 'GPT-4o',
    'gpt-4o-mini': 'GPT-4o Mini',
    o1: 'GPT-o1',
    'o1-mini': 'GPT-o1 Mini',
    'o3-mini': 'GPT-o3 Mini',
    'gemini-1.5-pro': 'Gemini Pro 1.5',
    'gemini-1.5-flash': 'Gemini Flash 1.5',
    'claude-3-5-sonnet-latest': 'Claude 3.5 Sonnet',
    'claude-3-5-haiku-latest': 'Claude 3.5 Haiku',
  };

  readonly modelOptions = Object.entries(this.ModelNameMap).map(([value, name]) => ({ name, value }));

  form!: FormGroup;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly applicationService: ApplicationService,
    private readonly integrationService: IntegrationService,
    private readonly studioService: StudioService,
    private readonly formBuilder: FormBuilder,
    private readonly globalUiService: GlobalUiService,
    private readonly abilityFactory: AbilityFactory,
    private readonly portalService: PortalService,
    private readonly router: Router,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
    this.agent$ = this.studioService.observeAgent(this.agentId);

    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(() => {
      void this.studioService.initialize();
    });

    this.form = this.formBuilder.group({
      model: new FormControl('', []),
      instructions: new FormControl('', []),
    });

    // Set default values for the modelName and instructions.
    this.agent$.pipe(take(1)).subscribe(async agent => {
      if (agent) {
        this.form.patchValue({ model: agent.modelName, instructions: Object.values(agent.instructions)[0] || '' });
        void this.handleIntegrationIdParam(activatedRoute.snapshot);
      }
    });

    this.form.get('model')?.valueChanges.subscribe(this.updateModel.bind(this));
    this.form.get('instructions')?.valueChanges.pipe(debounceTime(500)).subscribe(this.upsertInstructions.bind(this));
  }

  ngOnInit(): void {
    this.portalService.attach(this.studioFlyoutRef);
  }

  ngOnDestroy(): void {
    this.studioService.closeFlyout();
    this.portalService.detach();
  }

  private async handleIntegrationIdParam(snapshot: ActivatedRouteSnapshot): Promise<void> {
    const integrationId = getQueryParameter(INTEGRATION_ID_PARAMETER, snapshot);
    if (!integrationId) return;

    const integration = await this.integrationService.getIntegration(integrationId);
    if (integration && isAbilityType(integration.type)) {
      void this.onConnect(integration.type, {
        integrationId,
        integrationType: integration.type,
      });
    }
    const params = { ...snapshot.queryParams };
    delete params['integrationId'];
    void this.router.navigate([], { queryParams: params, replaceUrl: true });
  }

  async updateModel(value: AiChatModelName): Promise<void> {
    try {
      await this.studioService.updateAgent(this.agentId, { modelName: value });
    } catch (e) {
      console.error('Error:', e);
    }
  }

  async upsertInstructions(value: string): Promise<void> {
    const { instructions } = this.studioService.getAgentSchemaOrFail(this.agentId);
    const id = Object.keys(instructions)[0];
    try {
      if (id) {
        await this.studioService.updateInstruction(this.agentId, id, value);
      } else {
        await this.studioService.addInstruction(this.agentId, value);
      }
    } catch (e) {
      console.log('Error:', e);
    }
  }

  addAbility(): void {
    this.studioService.openFlyout();
  }

  get hasAbilities(): boolean {
    const agent = this.studioService.getAgentSchemaOrFail(this.agentId);
    return (
      !!agent.connectedAgents?.length ||
      !!agent.connectedIntegrations?.length ||
      !!agent.functions?.length ||
      !!Object.keys(agent.contexts).length
    );
  }

  inferContextType(context: AiChatbotContextMetadata): KnowledgeAbilityType {
    const fileExtensions = /\.(jpg|jpeg|png|gif|pdf|docx?|xlsx?|pptx?|txt|js|json|xml|csv)$/i;
    if (context.type) return context.type;
    if (context.text.startsWith('http:')) return 'url';
    if (fileExtensions.test(context.text)) return 'file';
    return 'text';
  }

  editConnector(integrationId: string, integrationType: IntegrationType): void {
    void this.onEdit(integrationType as AbilityType, { integrationId, integrationType });
  }

  deleteConnector(integrationId: string, integrationType: IntegrationType): void {
    void this.onDelete(integrationType as AbilityType, { integrationId, integrationType });
  }

  async onCreate<T extends AbilityType>(type: T, options?: AbilityCreateOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    const success = await ability.onCreate(this.agentId, options);
    if (success) {
      this.studioService.closeFlyout();
    }
  }

  async onConnect<T extends AbilityType>(type: T, options?: AbilityConnectOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    const success = await ability.onConnect(this.agentId, options);
    if (success) {
      this.studioService.closeFlyout();
    }
  }

  async onEdit<T extends AbilityType>(type: T, options?: AbilityEditOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    await ability.onEdit(this.agentId, options);
  }

  async onDelete<T extends AbilityType>(type: T, options?: AbilityEditOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    await ability.onDelete(this.agentId, options);
  }

  private getAbility<T extends AbilityType>(
    type: T,
  ): BaseAbility<AbilityCreateOptionsMap[T], AbilityConnectOptionsMap[T], AbilityEditOptionsMap[T]> {
    return this.abilityFactory.getAbility(type) as BaseAbility<
      AbilityCreateOptionsMap[T],
      AbilityConnectOptionsMap[T],
      AbilityEditOptionsMap[T]
    >;
  }

  get connectedIntegrationsByCategory(): Record<IntegrationCategory, Array<AiConnectedIntegrationMetadata>> {
    const agent = this.studioService.getAgentSchemaOrFail(this.agentId);
    return groupBy(
      (agent.connectedIntegrations || []).filter(i => isAbilityType(i.integrationType)),
      i => Integrations[i.integrationType].category,
    );
  }

  getCategoryName(category: string): string {
    return truthy(Categories[category as IntegrationCategory]).name;
  }

  get eligibleConnectors(): Array<AbilityTypeWithCreate> {
    const eligible = Object.entries(Integrations).filter(([type, content]) => {
      return !!AbilityConstructorMap[type as AbilityType] && content.available && !DefaultIntegrations.includes(type);
    });
    return eligible.map(([type]) => type as AbilityTypeWithCreate);
  }

  get existingConnectors(): Array<CpIntegration> {
    const integrations = this.integrationService.getAllIntegrations();
    const agent = this.studioService.getAgentSchemaOrFail(this.agentId);
    return integrations.filter(integration => {
      return (
        !!AbilityConstructorMap[integration.type as AbilityType] &&
        !(agent.connectedIntegrations || []).find(i => i.integrationId === integration.id)
      );
    });
  }

  get eligibleAiFunctions(): Array<AiFunctionMetadata> {
    const allAiFunctions = Object.values(
      this.applicationService.getCurrentApplicationOrFail().bundleData?.aiFunctions || {},
    );
    const agent = this.studioService.getAgentSchemaOrFail(this.agentId);
    const usedFunctions = agent.functions || [];
    return allAiFunctions.filter(
      f =>
        !usedFunctions.includes(getFunctionNameFromServiceFunctionName(f.serviceFunction)) &&
        !f.attributes?.integrationType?.length,
    );
  }

  get eligibleConnectedAgentIds(): Array<AiProfileId> {
    const schema = this.studioService.getSchemaOrFail();
    const allOtherAgentIds = Object.keys(schema.profiles).filter(id => id !== this.agentId);
    const agentIdsThatDoNotUseCurrentAgent: Array<AiProfileId> = [];
    const checkedAgentResults = new Map<AiAgentId, boolean>();

    for (const agentId of allOtherAgentIds) {
      const isDependencyLoop = this.checkIfAgentIdIsInConnectedAgentHierarchy(
        this.agentId,
        agentId,
        checkedAgentResults,
        schema.profiles,
      );
      if (!isDependencyLoop) {
        agentIdsThatDoNotUseCurrentAgent.push(agentId);
      }
    }

    const connectedAgents = schema.profiles[this.agentId]?.connectedAgents || [];
    return agentIdsThatDoNotUseCurrentAgent.filter(id => !connectedAgents.some(p => p.agentId === id));
  }

  private checkIfAgentIdIsInConnectedAgentHierarchy(
    agentId: AiAgentId,
    agentIdToCheck: AiAgentId,
    checkedAgentResults: Map<AiAgentId, boolean>,
    allProfiles: Record<AiAgentId, AiChatbotProfile>,
  ): boolean {
    const checkedResult = checkedAgentResults.get(agentIdToCheck);
    if (checkedResult !== undefined) {
      return checkedResult;
    }
    const agentToCheck = allProfiles[agentIdToCheck];
    if (!agentToCheck?.connectedAgents) {
      checkedAgentResults.set(agentIdToCheck, false);
      return false;
    }
    for (const agentInfo of agentToCheck.connectedAgents) {
      if (
        agentInfo.agentId === agentId ||
        this.checkIfAgentIdIsInConnectedAgentHierarchy(agentId, agentInfo.agentId, checkedAgentResults, allProfiles)
      ) {
        checkedAgentResults.set(agentIdToCheck, true);
        return true;
      }
    }
    checkedAgentResults.set(agentIdToCheck, false);
    return false;
  }
}
