import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { AbilityConstructorMap, AbilityMap } from '@squidcloud/console-web/app/studio/agent/abilities/factory';
import { assertTruthy } from 'assertic';

export type AbilityMetadata = {
  name?: string;
  description?: string;
  icon: string;
};

export type AbilityType = keyof AbilityMap;

export const KNOWLEDGE_ABILITIES = ['file', 'text', 'url'] as const;
export type KnowledgeAbilityType = (typeof KNOWLEDGE_ABILITIES)[number];

export type AbilityCreateOptionsMap = {
  [K in keyof AbilityMap]: AbilityMap[K] extends BaseAbility<infer TCreateOptions, unknown, unknown>
    ? TCreateOptions
    : never;
};

export type AbilityConnectOptionsMap = {
  [K in keyof AbilityMap]: AbilityMap[K] extends BaseAbility<unknown, infer TConnectOptions, unknown>
    ? TConnectOptions
    : never;
};

export type AbilityEditOptionsMap = {
  [K in keyof AbilityMap]: AbilityMap[K] extends BaseAbility<unknown, unknown, infer TDeleteOptions>
    ? TDeleteOptions
    : never;
};

type KeysWithDefinedOptions<T> = {
  [K in keyof T]: T[K] extends undefined ? never : K;
}[keyof T];

export type AbilityTypeWithCreate = KeysWithDefinedOptions<AbilityCreateOptionsMap>;
export type AbilityTypeWithConnect = KeysWithDefinedOptions<AbilityConnectOptionsMap>;
export type AbilityTypeWithEdit = KeysWithDefinedOptions<AbilityEditOptionsMap>;

export function getAbilityMetadata(type: AbilityType): AbilityMetadata {
  return AbilityConstructorMap[type].metadata;
}

export function isAbilityType(type: string): type is AbilityType {
  return type in AbilityConstructorMap;
}

export function getAbilityType(type: string): AbilityType {
  assertTruthy(isAbilityType(type));
  return type as AbilityType;
}
