import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getIntegration } from '../../utils/content';
import { IntegrationType } from '@squidcloud/client';
import { ActivatedRoute, Router } from '@angular/router';
import { getQueryParameter } from '@squidcloud/console-web/app/utils/http-utils';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';

@Component({
  selector: 'integration-header',
  styleUrls: ['./integration-header.component.scss'],
  templateUrl: './integration-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IntegrationHeaderComponent {
  @Input() title!: string;
  @Input() integrationType!: string;
  @Input() step: [number, number] = [-1, -1];
  @Input() withTabs = true;
  @Input() description?: string;
  @Input() secondaryTitle?: string;
  @Input() integrationPageText?: string;
  @Input() forceSubtitle?: string;

  readonly agentId?: string;

  constructor(
    { snapshot }: ActivatedRoute,
    private readonly router: Router,
    private readonly applicationService: ApplicationService,
  ) {
    this.agentId = getQueryParameter('agentId', snapshot);
  }

  get subtitleMessage(): string {
    return this.forceSubtitle || this.stepMessage || 'Integration';
  }

  getSecondaryTitle(): string {
    return this.secondaryTitle || getIntegration(this.integrationType as IntegrationType).name;
  }

  get secondaryDescription(): string {
    return (
      this.integrationPageText ||
      this.description ||
      getIntegration(this.integrationType as IntegrationType).description
    );
  }

  get stepMessage(): string {
    if (this.step[0] < 1) return '';
    return `Step ${this.pad(this.step[0])} of ${this.pad(this.step[1])}`;
  }

  private pad(number: number): string {
    return String(number).padStart(2, '0');
  }

  onClose(): void {
    if (!this.agentId) return;
    const application = this.applicationService.getCurrentApplicationOrFail();
    void this.router.navigate(['/application', application.appId, 'studio', this.agentId]);
  }
}
