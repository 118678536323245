import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { Observable } from 'rxjs';
import { AiChatbotProfile } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { AiFunctionMetadata } from '@squidcloud/internal-common/types/bundle-data.types';
import {
  AbilityConnectOptionsMap,
  AbilityCreateOptionsMap,
  AbilityTypeWithConnect,
  AbilityTypeWithCreate,
  isAbilityType,
  KNOWLEDGE_ABILITIES,
} from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import { Categories, Integrations } from '@squidcloud/console-web/app/integrations/utils/content';
import { groupBy } from '@squidcloud/internal-common/utils/object';
import { getEntries } from '@squidcloud/console-web/app/utils/angular-utils';
import { IntegrationCategory } from '@squidcloud/console-common/types/integration.types';
import { truthy } from 'assertic';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';

type CreateEvent = {
  [K in AbilityTypeWithCreate]: { type: K; options: AbilityCreateOptionsMap[K] };
}[AbilityTypeWithCreate];

type ConnectEvent = {
  [K in AbilityTypeWithConnect]: { type: K; options: AbilityConnectOptionsMap[K] };
}[keyof AbilityConnectOptionsMap];

@Component({
  selector: 'studio-flyout',
  templateUrl: './flyout.component.html',
  styleUrl: './flyout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StudioFlyoutComponent implements OnChanges {
  readonly list = [{ name: 'Knowledge Base', abilities: KNOWLEDGE_ABILITIES }];
  readonly expandedObs = this.studioService.observeFlyout();

  readonly isAbilityType = isAbilityType;
  readonly getEntries = getEntries;
  readonly Categories = Categories;
  readonly getFunctionNameFromServiceFunctionName = getFunctionNameFromServiceFunctionName;

  @Output() create = new EventEmitter<CreateEvent>();
  @Output() connect = new EventEmitter<ConnectEvent>();

  agent$: Observable<AiChatbotProfile | undefined>;
  @Input({ required: true }) agentId!: string;
  @Input({ required: true }) eligibleAiFunctions!: Array<AiFunctionMetadata>;
  @Input({ required: true }) eligibleConnectedAgentIds!: Array<string>;
  @Input({ required: true }) eligibleConnectors!: Array<AbilityTypeWithCreate>;
  @Input({ required: true }) existingConnectors!: Array<CpIntegration>;

  constructor(private readonly studioService: StudioService) {
    this.agent$ = this.studioService.observeAgent(this.agentId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['agentId']) {
      this.agent$ = this.studioService.observeAgent(this.agentId);
    }
  }

  handleClose(): void {
    this.studioService.closeFlyout();
  }

  get eligibleConnectorsByCategory(): Record<IntegrationCategory, Array<AbilityTypeWithCreate>> {
    return groupBy(this.eligibleConnectors, type => Integrations[type].category);
  }

  get existingConnectorsByCategory(): Record<IntegrationCategory, Array<CpIntegration>> {
    return groupBy(this.existingConnectors, i => Integrations[i.type].category);
  }

  getAgentDescription(agentId: string): string | undefined {
    return this.studioService.getAgentSchemaOrFail(agentId).description;
  }

  getCategoryName(category: string): string {
    return truthy(Categories[category as IntegrationCategory]).name;
  }

  get hasExisting(): boolean {
    return (
      !!this.eligibleAiFunctions.length || !!this.existingConnectors.length || !!this.eligibleConnectedAgentIds.length
    );
  }
}
