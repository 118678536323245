<div class="knowledge_card border" [ngClass]="{ hide: !show, warning }">
  <div class="card_wrapper" [ngClass]="{ hide: !show }">
    @if (!hideClose) {
      <button class="close_button" (click)="onDismiss()">
        <mat-icon svgIcon="close_icon"></mat-icon>
      </button>
    }
  </div>
  <div class="content" [ngClass]="{ hide: !show }">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
