import { Injectable } from '@angular/core';
import { FileAbility } from '@squidcloud/console-web/app/studio/agent/abilities/file.ability';
import { TextAbility } from '@squidcloud/console-web/app/studio/agent/abilities/text.ability';
import { UrlAbility } from '@squidcloud/console-web/app/studio/agent/abilities/url.ability';
import { AiFunctionAbility } from '@squidcloud/console-web/app/studio/agent/abilities/ai-function.ability';
import { ConnectedAgentAbility } from '@squidcloud/console-web/app/studio/agent/abilities/connected-agent.ability';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { AbilityType } from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { ServicenowAbility } from '@squidcloud/console-web/app/studio/agent/abilities/servicenow.ability';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { Router } from '@angular/router';
import { BuiltInDbAbility } from '@squidcloud/console-web/app/studio/agent/abilities/built_in_db.ability';
import { PostgresAbility } from '@squidcloud/console-web/app/studio/agent/abilities/postgres.ability';
import { Squid } from '@squidcloud/client';
import { MysqlAbility } from '@squidcloud/console-web/app/studio/agent/abilities/mysql.ability';
import { MssqlAbility } from '@squidcloud/console-web/app/studio/agent/abilities/mssql.ability';
import { JiraAbility } from '@squidcloud/console-web/app/studio/agent/abilities/jira.ability';
import { MongoAbility } from '@squidcloud/console-web/app/studio/agent/abilities/mongo.ability';
import { ZendeskAbility } from '@squidcloud/console-web/app/studio/agent/abilities/zendesk.ability';

export const AbilityConstructorMap = {
  file: FileAbility,
  text: TextAbility,
  url: UrlAbility,
  connected_agent: ConnectedAgentAbility,
  ai_function: AiFunctionAbility,
  servicenow: ServicenowAbility,
  built_in_db: BuiltInDbAbility,
  postgres: PostgresAbility,
  jira: JiraAbility,
  zendesk: ZendeskAbility,
  mssql: MssqlAbility,
  mysql: MysqlAbility,
  mongo: MongoAbility,
} as const;

export type AbilityMap = {
  file: FileAbility;
  text: TextAbility;
  url: UrlAbility;
  connected_agent: ConnectedAgentAbility;
  ai_function: AiFunctionAbility;
  servicenow: ServicenowAbility;
  built_in_db: BuiltInDbAbility;
  postgres: PostgresAbility;
  jira: JiraAbility;
  zendesk: ZendeskAbility;
  mssql: MssqlAbility;
  mysql: MysqlAbility;
  mongo: MongoAbility;
};

@Injectable({
  providedIn: 'root',
})
export class AbilityFactory {
  constructor(
    protected readonly applicationService: ApplicationService,
    protected readonly studioService: StudioService,
    protected readonly snackBar: SnackBarService,
    protected readonly globalUiService: GlobalUiService,
    protected readonly router: Router,
    protected readonly squid: Squid,
  ) {}

  getAbility<T extends AbilityType>(type: T): AbilityMap[T] {
    const Ability = AbilityConstructorMap[type];
    return new Ability({
      applicationService: this.applicationService,
      studioService: this.studioService,
      snackBar: this.snackBar,
      globalUiService: this.globalUiService,
      router: this.router,
      squid: this.squid,
    }) as AbilityMap[T];
  }
}
