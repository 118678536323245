import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'dialog-maximize-button',
  templateUrl: './dialog-maximize-button.component.html',
  styleUrls: ['./dialog-maximize-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DialogMaximizeButtonComponent {
  isFullScreenMode = false;

  toggleMaximizedState(): void {
    this.isFullScreenMode = !this.isFullScreenMode;
    const dialogElement = document.querySelector('mat-dialog-container') as HTMLElement;
    if (dialogElement) {
      if (this.isFullScreenMode) {
        dialogElement.classList.add('maximized_dialog');
      } else {
        dialogElement.classList.remove('maximized_dialog');
      }
    }
  }
}
