<div mat-dialog-title data-testid="creation-script-dialog-title">Creation script for all secrets</div>

@if (apiScriptSubject | async; as apiScript) {

  <div class="form">
    <mat-form-field appearance="outline">
      <mat-label>Application ID</mat-label>
      <input matInput [(ngModel)]="applicationId" (input)="updateScript()" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="api_key_field">
      <mat-label>API Key</mat-label>
      <input matInput [(ngModel)]="apiKey" (input)="updateScript()" />
    </mat-form-field>
  </div>
  <mat-dialog-content class="script_dialog_content">
    <dialog-maximize-button></dialog-maximize-button>
    <dialog-close-button></dialog-close-button>
    <doc markdownFileUrl="assets/secrets/api-script-template.md" [replaceableParams]="{apiScript}"></doc>
  </mat-dialog-content>
} @else {
  <div class="loading_indicator_block">
    <loading-indicator></loading-indicator>
  </div>
}
