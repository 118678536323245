import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { PortalService } from './portal.service';

@Component({
  selector: 'portal-host',
  template: `<ng-template cdkPortalOutlet></ng-template>`,
  standalone: false,
})
export class PortalHostComponent implements AfterViewInit {
  @ViewChild(CdkPortalOutlet, { static: false }) portalOutlet!: CdkPortalOutlet;

  constructor(private portalService: PortalService) {}

  ngAfterViewInit(): void {
    this.portalService.setPortalOutlet(this.portalOutlet);
  }
}
