import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormConfig } from '@squidcloud/console-web/app/global/components/magic-form/magic-form.component';
import { SquidAngularValidators } from '@squidcloud/console-web/app/utils/angular-utils';
import { getPathParameter } from '@squidcloud/console-web/app/utils/http-utils';
import { isEmail, truthy } from 'assertic';
import { CONTACT_LIST_COLLECTION, CpContact } from '@squidcloud/console-common/types/contact.types';
import { Squid } from '@squidcloud/client';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { ActivatedRoute } from '@angular/router';
import { SQUID_SUPPORT_EMAIL } from '@squidcloud/internal-common/utils/squid.constants';

export const USER_LOCK_PAGE_PATH = 'lock';
const EMAIL_PARAMETER = 'email';

const VARIANT_PARAMETER = 'variant';
export const VARIANT_PARAMETERS = ['new-user', 'marketplace'] as const;
export type VariantParameter = (typeof VARIANT_PARAMETERS)[number];

// Note: Before setting it to false check the backend trigger: it requires captcha for all requests.
const isCaptchaEnabled = true;

/** Shows registration lock status details for new users. */
@Component({
  templateUrl: './user-lock-page.component.html',
  styleUrl: './user-lock-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserLockPageComponent {
  protected readonly formConfig: FormConfig<Record<string, string>> = {
    formElements: [
      {
        type: 'input',
        required: true,
        nameInForm: 'name',
        label: 'Your name',
      },
      {
        type: 'input',
        required: true,
        readonly: isEmail(getPathParameter('email')),
        nameInForm: 'email',
        label: 'Your work email',
        defaultValue: getPathParameter('email'),
        extraValidators: [SquidAngularValidators.email],
      },
      {
        type: 'input',
        required: true,
        nameInForm: 'companyName',
        label: 'Company name',
      },
      {
        type: 'input',
        required: true,
        nameInForm: 'jobTitle',
        label: 'Job title',
      },
      {
        type: 'input',
        required: true,
        nameInForm: 'phone',
        label: 'Contact number',
      },
      {
        type: 'textarea',
        required: false,
        nameInForm: 'message',
        label: 'Tell us more about your project and timeline',
        attributes: { autosize: true, minRows: 3, maxRows: 50 },
      },
    ],
    submitButtonText: 'Submit',
    useCaptcha: isCaptchaEnabled,
    onSubmit: fields => this.onSubmit(fields),
  };

  protected readonly isMarketplaceMode: boolean;

  constructor(
    private readonly squid: Squid,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    activatedRoute: ActivatedRoute,
  ) {
    this.isMarketplaceMode =
      (activatedRoute.snapshot.queryParamMap.get(VARIANT_PARAMETER) as VariantParameter) === 'marketplace';
    if (!this.isMarketplaceMode) {
      this.formConfig.formElements.push({
        type: 'input',
        required: false,
        nameInForm: 'accessCode',
        label: 'Access code (optional)',
      });
    }
  }

  async onSubmit({
    companyName,
    jobTitle,
    email,
    name,
    phone,
    message,
    captchaToken,
    accessCode,
  }: Record<string, string>): Promise<void> {
    let isReadyToSignIn = false;
    if (accessCode) {
      try {
        const response = await callBackendExecutable(this.squid, 'grantConsoleAccessByCode', { accessCode, email });
        if (response.errorMessage) {
          this.snackBar.warning(response.errorMessage);
          return;
        }
        isReadyToSignIn = true;
      } catch (error) {
        console.error('Unexpected error', error);
        this.snackBar.warning('Unexpected error. Please try later.');
        return;
      }
    }

    try {
      const contact: CpContact = {
        name,
        email,
        phone,
        captcha: isCaptchaEnabled ? truthy(captchaToken, 'captchaToken is not defined') : 'not-used',
        message: message + '\n\nCompany name: ' + companyName + '\nJob title: ' + jobTitle,
        location: 'registration-page',
        submissionDate: new Date(),
      };
      await this.squid.collection<CpContact>(CONTACT_LIST_COLLECTION).doc().insert(contact);
    } catch (error) {
      console.error('Unexpected error', error);
      if (!isReadyToSignIn) {
        this.snackBar.warning('Unexpected error. Please try later.');
      }
      return;
    }
    const title = isReadyToSignIn ? 'Access is granted' : 'Submission Successful';
    const messageLines = isReadyToSignIn
      ? ['Thank you for your interest in Squid AI!', 'The access to the console is granted. You can Sign in now.']
      : ['Thank you for your interest in Squid AI!', 'We will get back to you shortly with more information.'];
    const buttonText = isReadyToSignIn ? 'Sign in' : 'Close';
    await this.globalUiService.showMessageDialog(title, messageLines, buttonText);
    if (isReadyToSignIn) {
      window.location.href = '/';
    } else {
      window.location.href = 'https://getsquid.ai/';
    }
  }

  static buildPagePath(email: string, variant: VariantParameter): string {
    const query = `${EMAIL_PARAMETER}=${encodeURIComponent(email)}&${VARIANT_PARAMETER}=${encodeURIComponent(variant)}`;
    return `/${USER_LOCK_PAGE_PATH}?${query}`;
  }

  protected readonly SQUID_SUPPORT_EMAIL = SQUID_SUPPORT_EMAIL;
}
