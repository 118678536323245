import { IntegrationId } from './communication.public-types';

/** List of all integration types supported by Squid. */
export const INTEGRATION_TYPES = [
  'active_directory',
  'ai_agents',
  'ai_chatbot',
  'algolia',
  'alloydb',
  'api',
  'auth0',
  'azure_cosmosdb',
  'azure_postgresql',
  'azure_sql',
  'bigquery',
  'built_in_db',
  'built_in_gcs',
  'built_in_queue',
  'built_in_s3',
  'cassandra',
  'clickhouse',
  'cloudsql',
  'cockroach',
  'cognito',
  'confluence',
  'confluent',
  'datadog',
  'db2',
  'descope',
  'documentdb',
  'dynamodb',
  'elastic_enterprise_search',
  'elastic_observability',
  'elastic_search',
  'firebase_auth',
  'firestore',
  'gcs',
  'google_docs',
  'google_drive',
  'graphql',
  'hubspot',
  'jira',
  'jwt_hmac',
  'jwt_rsa',
  'kafka',
  'linear', // Link to docs.
  'mariadb',
  'monday',
  'mongo',
  'mssql',
  'mysql',
  'newrelic',
  'okta',
  'onedrive',
  'oracledb',
  'pinecone',
  'postgres',
  'redis',
  's3',
  'salesforce_crm',
  'sap_hana',
  'sentry',
  'servicenow',
  'slack',
  'snowflake',
  'spanner',
  'xata',
  'zendesk',
] as const;

export const DATA_INTEGRATION_TYPES = [
  'bigquery',
  'built_in_db',
  'clickhouse',
  'cockroach',
  'mongo',
  'mssql',
  'mysql',
  'oracledb',
  'postgres',
  'sap_hana',
  'snowflake',
] as const;

export const AUTH_INTEGRATION_TYPES = [
  'auth0',
  'jwt_rsa',
  'jwt_hmac',
  'cognito',
  'okta',
  'descope',
  'firebase_auth',
] as const;

export const GRAPHQL_INTEGRATION_TYPES = ['graphql', 'linear'] as const;
export const HTTP_INTEGRATION_TYPES = ['api', 'confluence'] as const;

export type IntegrationType = (typeof INTEGRATION_TYPES)[number];

export const INTEGRATION_SCHEMA_TYPES = ['data', 'api', 'graphql'] as const;

export type IntegrationSchemaType = (typeof INTEGRATION_SCHEMA_TYPES)[number];

export const BUILT_IN_DB_INTEGRATION_ID: IntegrationId = 'built_in_db';
export const BUILT_IN_QUEUE_INTEGRATION_ID: IntegrationId = 'built_in_queue';
export const AI_AGENTS_INTEGRATION_ID: IntegrationId = 'ai_agents';
/** ID for the cloud specific storage integration: s3 (built_in_s3) or gcs (built_in_gcs). */
export const BUILT_IN_STORAGE_INTEGRATION_ID: IntegrationId = 'built_in_storage';

export interface IntegrationInfo<ConfigurationType = Record<string, any> | undefined> {
  id: IntegrationId;
  type: IntegrationType;
  creationDate?: Date;
  updateDate?: Date;
  configuration: ConfigurationType;
}
