import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { captureMarketplaceParametersOnAppInit } from '@squidcloud/console-web/app/utils/marketplace-web-utils';
import { initPlaywrightModeSupport } from '@squidcloud/console-web/app/utils/e2e-test-web-utils';

if (environment.production) {
  enableProdMode();
}

// Run some functions before Angular is initialized.
captureMarketplaceParametersOnAppInit();
initPlaywrightModeSupport();

// Start Angular.
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
