<div mat-dialog-title>Log entry</div>

<mat-dialog-content class="log_entry_dialog">
  <dialog-maximize-button></dialog-maximize-button>
  <dialog-close-button></dialog-close-button>

  <div class="logs_table">
    <div class="row header_row">
      <div class="cell">Timestamp</div>
      <div class="cell">Level</div>
      <div class="cell">Service</div>
    </div>

    <div class="row">
      <div class="cell timestamp_cell">
        <div class="log_level_indicator"></div>
        {{ logEntry.timestamp | date: 'medium' }}
      </div>
      <div class="cell">
        <log-level [level]="logEntry.level" />
      </div>
      <div class="cell">{{ logEntry.service }}</div>
    </div>
  </div>

  <div class="log_message_container">
    <button class="copy_button secondary_icon_button" (click)="copyLogMessage()" mat-flat-button>
      <mat-icon svgIcon="copy_icon" class="menu_icon"></mat-icon>
    </button>

    <div class="log_message">{{ getLogMessageAndTags(logEntry) }}</div>
  </div>
</mat-dialog-content>
