import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AiStatusMessage } from '@squidcloud/client';
import { JsonEditorOptions } from '@squidcloud/console-web/app/db-browser/json-editor.component';

@Component({
  selector: 'status-card',
  templateUrl: './status-card.component.html',
  styleUrl: './status-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StatusCardComponent {
  @Input() expanded = false;
  @Input({ required: true }) status!: AiStatusMessage;

  readonly editorOptions = {
    enableSort: false,
    mode: 'view',
    history: false,
    expandAll: true,
    enableTransform: false,
    mainMenuBar: false,
    navigationBar: false,
    search: false,
    statusBar: false,
    sortObjectKeys: false,
  } as JsonEditorOptions;

  handleToggle(): void {
    this.expanded = !this.expanded;
  }
}
