<div class="card">
  <button class="header" (click)="handleToggle()">
    <div class="icon_and_name">
      <img [src]="getImage()" class="icon" />
      <span class="name">{{ getName() }}</span>
    </div>
    <div class="plus_and_toggle" [ngClass]="{ open: isMenuOpen }">
      @if (add.observed) {
        <button mat-flat-button (click)="handlePlus($event)" class="plus secondary_icon_button medium_button">
          <mat-icon class="plus_icon" svgIcon="plus_icon" />
        </button>
      } @else {
        <button
          [matMenuTriggerFor]="actionMenu"
          class="plus secondary_icon_button medium_button"
          mat-flat-button
          (click)="$event.stopPropagation()"
          #menuTrigger="matMenuTrigger"
        >
          <mat-icon class="menu_icon" svgIcon="menu_dots_icon"></mat-icon>
        </button>
      }
      <mat-icon [ngClass]="{ expanded: expanded }" class="toggle_icon" svgIcon="chevron_right_icon"></mat-icon>
    </div>
  </button>
  <div [ngClass]="{ expanded: expanded }" class="content_wrapper">
    <div class="content">
      <p class="description">{{ getDescription() }}</p>
      @if (add.observed) {
        <hr />
        <button mat-flat-button (click)="add.emit()" class="primary_button medium_button">Add</button>
      }
    </div>
  </div>

  <mat-menu #actionMenu="matMenu" class="integration_menu" xPosition="before">
    @if (edit.observed) {
      <button class="menu_item" mat-menu-item data-testid="edit-integration-menu-button" (click)="edit.emit()">
        <mat-icon class="icon" svgIcon="edit_icon"></mat-icon>
        Edit
      </button>
    }
    <button class="menu_item red_menu_item" mat-menu-item (click)="delete.emit()">
      <mat-icon class="icon" svgIcon="trash_icon"></mat-icon>
      Delete
    </button>
  </mat-menu>
</div>
