@let isExpanded = expandedObs | async;
@let agent = agent$ | async;

<div class="flyout">
  @if (isExpanded) {
    <div class="overlay" (click)="handleClose()"></div>
  }
  <div class="outer" [ngClass]="{ expanded: isExpanded }">
    <div class="inner">
      <div class="header">
        <h2>Add Agent Abilities</h2>
        <button class="close" (click)="handleClose()">
          <mat-icon class="close_icon" svgIcon="close_icon" />
        </button>
      </div>
      @if (agent) {
        <mat-tab-group
          animationDuration="0ms"
          color="primary"
          [disableRipple]="true"
          mat-align-tabs="start"
          #tabGroup
          mat-stretch-tabs="false"
        >
          <mat-tab label="New">
            <ng-template matTabContent>
              <div class="categories">
                @for (section of list; track section.name) {
                  <abilities-category [title]="section.name">
                    <div class="cards">
                      @for (ability of section.abilities; track ability) {
                        <abilities-card [type]="ability" (add)="connect.emit({ type: ability, options: undefined })" />
                      }
                    </div>
                  </abilities-category>
                }
                @for (entry of getEntries(eligibleConnectorsByCategory); track entry[0]) {
                  <abilities-category [title]="getCategoryName(entry[0])">
                    <div class="cards">
                      @for (connector of entry[1]; track connector) {
                        <abilities-card
                          [type]="connector"
                          (add)="create.emit({ type: connector, options: { integrationType: connector } })"
                        />
                      }
                    </div>
                  </abilities-category>
                }
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Existing">
            <ng-template matTabContent>
              @if (hasExisting) {
                <div class="categories">
                  @for (entry of getEntries(existingConnectorsByCategory); track entry[0]) {
                    <abilities-category [title]="getCategoryName(entry[0])">
                      <div class="cards">
                        @for (integration of entry[1]; track integration.id) {
                          @if (isAbilityType(integration.type)) {
                            <abilities-card
                              [type]="integration.type"
                              [name]="integration.id"
                              (add)="
                                connect.emit({
                                  type: integration.type,
                                  options: { integrationType: integration.type, integrationId: integration.id },
                                })
                              "
                            />
                          }
                        }
                      </div>
                    </abilities-category>
                  }
                  @if (eligibleConnectedAgentIds.length) {
                    <abilities-category title="Agents">
                      <div class="cards">
                        @for (agentId of eligibleConnectedAgentIds; track agentId) {
                          <abilities-card
                            type="connected_agent"
                            [name]="agentId"
                            [description]="getAgentDescription(agentId)"
                            (add)="connect.emit({ type: 'connected_agent', options: { connectedAgentId: agentId } })"
                          />
                        }
                      </div>
                    </abilities-category>
                  }
                  @if (eligibleAiFunctions.length) {
                    <abilities-category title="AI Functions">
                      <div class="cards">
                        @for (metadata of eligibleAiFunctions; track metadata.serviceFunction) {
                          <abilities-card
                            type="ai_function"
                            [name]="getFunctionNameFromServiceFunctionName(metadata.serviceFunction)"
                            [description]="metadata.description"
                            (add)="
                              connect.emit({
                                type: 'ai_function',
                                options: { serviceFunction: metadata.serviceFunction },
                              })
                            "
                          />
                        }
                      </div>
                    </abilities-category>
                  }
                </div>
              } @else {
                <div class="zero_state">
                  <mat-icon class="icon" svgIcon="squid_bot" />
                  <span class="headline">No existing abilities to use here</span>
                  <span class="subhead">Try connecting new abilities</span>
                </div>
              }
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      }
    </div>
  </div>
</div>
