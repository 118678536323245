<div class="spinner_container" *ngIf="!(applicationInitializedObs | async)">
  <mat-spinner class="spinner"></mat-spinner>
</div>
<ai-test-chat-fly-out></ai-test-chat-fly-out>
@let isExpanded = !!(isExpandedObs | async);
@let theme = themeObs | async;
<div class="sidebar_layout">
  <div [ngClass]="{ expanded: isExpanded, light: theme === 'light' }" class="sidebar">
    <div class="top">
      <div class="logo_container">
        <div *ngIf="isExpanded" class="logo">
          <mat-icon svgIcon="logo_long" class="logo_icon long"></mat-icon>
        </div>

        <div *ngIf="!isExpanded" class="logo">
          <mat-icon svgIcon="logo" class="logo_icon short"></mat-icon>
        </div>
      </div>

      @for (section of sidebarMenuItems; track section; let i = $index) {
        @if (i > 0) {
          <div class="little_wave_wrapper">
            <mat-icon class="little_wave_icon" svgIcon="little_wave_icon"></mat-icon>
          </div>
        }
        @for (item of section; track item.id) {
          @if (isMenuItemVisible(item)) {
            <sidebar-menu-item [isActive]="isMenuItemActive(item)" [isMenuExpanded]="isExpanded" [item]="item" />
          }
        }
      }
    </div>

    <div class="bottom">
      <sidebar-menu-item
        *ngIf="organizationMenuItem"
        [isActive]="isMenuItemActive(organizationMenuItem)"
        [isMenuExpanded]="isExpanded"
        [item]="organizationMenuItem"
      >
      </sidebar-menu-item>
      <sidebar-menu-item
        [isMenuExpanded]="isExpanded"
        [item]="documentationMenuItem"
        [isActive]="false"
      ></sidebar-menu-item>

      <ng-container *ngIf="accountService.user$ | async as user">
        <sidebar-menu-item
          [isMenuExpanded]="isExpanded"
          [item]="adminMenuItem"
          [isActive]="false"
          *ngIf="isSysadminUserEmail(user.email)"
        ></sidebar-menu-item>
      </ng-container>

      <div
        (click)="setIsCollapsed(isExpanded)"
        [ngClass]="{ menu_collapsed: !isExpanded }"
        class="collapse_or_expand_button"
      >
        <mat-icon class="icon" svgIcon="collapse_icon"></mat-icon>
        <div class="text">Collapse</div>
      </div>
    </div>
  </div>

  <div class="right">
    <div class="main_and_header">
      <app-header class="header" [ngClass]="{ expanded: isExpanded }"></app-header>
      <div class="main">
        <div class="content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <rudder-chat />
</div>
