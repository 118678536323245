import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApplicationService } from '../application.service';
import { LogEntry, LogLevel } from './logs.types';
import { AppId, Pagination, Squid } from '@squidcloud/client';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CpApplication, CpIntegration, LogEntryInDb } from '@squidcloud/console-common/types/application.types';
import { AccountService } from '../../account/account.service';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { MatSelectChange } from '@angular/material/select';
import { MILLIS_PER_MINUTE } from '@squidcloud/internal-common/types/time-units';
import { LogsTable } from '@squidcloud/console-web/app/lib/logs/logs-table';
import { IntegrationService } from '@squidcloud/console-web/app/integrations/integration.service';
import { ActivatedRoute } from '@angular/router';
import { LogEntryDialogComponent } from '@squidcloud/console-web/app/application/logs/log-entry-dialog/log-entry-dialog.component';

interface LogLevelOption {
  label: string;
  value: LogLevel | undefined;
}

@Component({
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LogsComponent extends LogsTable<LogEntryInDb, LogEntry> implements OnInit {
  readonly allLogLevelOptions: Array<LogLevelOption> = [
    { label: 'All', value: undefined },
    { label: 'Trace', value: 'trace' },
    { label: 'Debug', value: 'debug' },
    { label: 'Info', value: 'info' },
    { label: 'Warning', value: 'warn' },
    { label: 'Error', value: 'error' },
  ];
  /** The period in minutes from Date.now() to display log messages. */
  currentLogLevel: string | undefined;

  searchKeywordsSubject = new BehaviorSubject<string>('');

  appIdOptions: { label: string; value: string }[] | undefined = undefined;
  selectedAppId: string | undefined = undefined;

  constructor(
    activatedRoute: ActivatedRoute,
    applicationService: ApplicationService,
    integrationService: IntegrationService,
    squid: Squid,
    cdr: ChangeDetectorRef,
    private readonly accountService: AccountService,
    private readonly dialog: MatDialog,
  ) {
    super(activatedRoute, applicationService, integrationService, squid, cdr);

    this.searchKeywordsSubject.pipe(debounceTime(300), takeUntilDestroyed()).subscribe(async () => {
      await this.waitForRefreshToFinish();
      void this.executeQuery();
    });
  }

  ngOnInit(): void {
    super.initialize();
  }

  override parseLogEntry(logEntryInDb: LogEntryInDb): LogEntry {
    const messages = parseAndFormatLogMessage(logEntryInDb);

    return {
      id: logEntryInDb.id,
      timestamp: logEntryInDb.timestamp,
      message: messages.join(' '),
      level: logEntryInDb.level as LogLevel,
      service: 'squid',
      tags: logEntryInDb.tags || {},
    };
  }

  handleApplicationChange(application: CpApplication | undefined | null, _: CpIntegration | undefined | null): void {
    if (application && parseAppId(application.appId).environmentId === 'dev') {
      const appId = application.appId;
      this.appIdOptions = [
        { label: 'dev', value: appId },
        { label: 'local', value: appId + '-' + this.accountService.getUserOrFail().squidDeveloperId },
      ];
      this.selectedAppId = appId;
    } else {
      this.appIdOptions = undefined;
      this.selectedAppId = undefined;
    }
  }

  onSourceSelectionChange(event: MatSelectChange): void {
    this.selectedAppId = event.value;
    void this.executeQuery();
  }

  getPagination(appId: AppId): Pagination<LogEntryInDb> {
    const queryBuilder = this.squid
      .collection<LogEntryInDb>('logs', 'clickhouse')
      .query()
      .eq('appId', this.selectedAppId || appId)
      .eq('isExposedToUser', 1)
      .sortBy('timestamp', false);
    if (this.logDisplayPeriodInMinutes) {
      const fromDate = new Date(Date.now() - this.logDisplayPeriodInMinutes * MILLIS_PER_MINUTE);
      queryBuilder.gte('timestamp', fromDate);
    }

    if (this.currentLogLevel !== undefined) {
      queryBuilder.eq('level', this.currentLogLevel);
    }

    if (this.searchKeywordsSubject.value !== '') {
      queryBuilder.like('message', `%${this.searchKeywordsSubject.value}%`, false);
    }

    return queryBuilder.dereference().paginate({ pageSize: 100, subscribe: false });
  }

  showLogEntryDialog(logEntry: LogEntry): void {
    const config: MatDialogConfig = {
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: { logEntry },
    };

    this.dialog.open(LogEntryDialogComponent, config);
  }

  handleLogLevelChanged(value: string): void {
    this.currentLogLevel = value;
    void this.executeQuery();
  }

  onSearchKeywordChanged(event: Event): void {
    const input = event.target as HTMLInputElement;
    const keyword = input.value;
    this.searchKeywordsSubject.next(keyword);
  }
}

function parseAndFormatLogMessage(logEntryInDb: LogEntryInDb): string[] {
  const result: string[] = [];
  try {
    const parseResult = JSON.parse(logEntryInDb.message) as unknown;
    if (Array.isArray(parseResult)) {
      result.push(...parseResult);
    } else if (typeof parseResult === 'string') {
      result.push(parseResult);
    } else {
      result.push(logEntryInDb.message);
    }
  } catch (_) {
    // Message is not in JSON format: show as it is.
    result.push(logEntryInDb.message);
  }
  return result
    .map(message => (typeof message === 'object' ? JSON.stringify(message, null, 2) : message))
    .map(formatStackTrace);
}

/** Improves stack trace formatting: splits into lines, removes inner tenant paths that may take >50% of every line space. */
function formatStackTrace(message: string): string {
  // TODO: We have other types here accidentally?
  if (!message || typeof (message as unknown) !== 'string') return message;

  try {
    const stackToken = '"stack": "';
    const webpackToken = 'webpack:';
    if (!message.includes(stackToken)) return message;
    const lines = message.split('\n').map(line => {
      const isStackTraceLine = line.trimStart().startsWith(stackToken);
      if (isStackTraceLine) {
        const stackLines = line.split('\\n').map(stackLine => {
          const openBraceIdx = stackLine.indexOf('(');
          const webpackStartIdx = openBraceIdx > 0 ? stackLine.indexOf(webpackToken, openBraceIdx) : -1;
          if (openBraceIdx > 0 && webpackStartIdx > 0) {
            const prefix = stackLine.substring(0, openBraceIdx + 1);
            let suffix = stackLine.substring(webpackStartIdx + webpackToken.length + 1);
            suffix = suffix.startsWith('/') ? suffix.substring(1) : suffix; // Local backend may have an extra '/'.
            return prefix + suffix;
          }
          return stackLine;
        });
        line = stackLines.join('\n');
      }
      return line;
    });
    return lines.join('\n');
  } catch (error) {
    // Whatever error happens inside it should not block a client.
    console.error('Error formatting message:', message, error);
    return message;
  }
}
