import { SquidRegion } from '@squidcloud/client';
import { Environment } from './environment.type';
import { Stage } from '@squidcloud/internal-common/types/stage';
import { CONSOLE_APPLICATION_ID } from '@squidcloud/console-common/types/organization.types';

declare const CONSOLE_URL: string;
declare const STRIPE_PUBLISHABLE_KEY: string;
declare const SQUID_REGION: SquidRegion;
declare const AUTH0_DOMAIN: string;
declare const AUTH0_CUSTOM_DOMAIN: string;
declare const AUTH0_CLIENT: string;
declare const SEGMENT_WRITE_KEY: string;
declare const CONSOLE_ENV: Stage;
declare const GIT_REVISION: string;
declare const BUILD_DATE: string;

export const environment: Environment = {
  production: true,
  squidAppId: CONSOLE_APPLICATION_ID,
  baseUrl: CONSOLE_URL,
  squidRegion: SQUID_REGION,
  auth0: {
    domain: AUTH0_DOMAIN,
    customDomain: AUTH0_CUSTOM_DOMAIN,
    clientId: AUTH0_CLIENT,
  },
  stripePublishableKey: STRIPE_PUBLISHABLE_KEY,
  recaptchaSiteKey: '6LcI-kspAAAAAHAmh-fLTqedlxpdHmMBAXtuKEKY',
  segmentWriteKey: SEGMENT_WRITE_KEY,
  stage: CONSOLE_ENV,
  buildMetadata: { gitRevision: GIT_REVISION, buildDate: BUILD_DATE },
};
