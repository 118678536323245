<div class="studio-home">
  <div class="header">
    <h1 class="title">Squid Agent Studio</h1>
    <button
      mat-flat-button
      data-testid="add-ai-agent-button"
      class="primary_button large_button"
      (click)="createAgent()"
    >
      Create AI Agent
    </button>
  </div>

  @let schema = agents$ | async;
  @let application = application$ | async;

  <div class="agents">
    @if (application) {
      @if (schema) {
        @if (hasAgents) {
          @for (agentId of getSortedKeys(schema.profiles); track agentId) {
            @let agent = schema.profiles[agentId];
            @let topAbilities = getTopAbilities(agentId);

            <a class="agent" [routerLink]="['/application', application.appId, 'studio', agentId]">
              <div>
                <span class="id">{{ agentId }}</span>
                <p class="description">
                  {{ agent.description }}
                </p>
              </div>
              <div class="badge_and_icons">
                @if (agent.isPublic) {
                  <div class="outline_badge public">Public</div>
                } @else {
                  <div class="outline_badge private">Private</div>
                }
                <div class="ability_icons">
                  @for (type of topAbilities.top; track type) {
                    <img [src]="getImage(type)" class="ability_icon" />
                  }
                  @if (topAbilities.count) {
                    <div class="ability_icon count_icon">+{{ topAbilities.count }}</div>
                  }
                </div>
              </div>
            </a>
          }
        }
      }
    }
  </div>

  @if (schema) {
    @if (!hasAgents) {
      <knowledge-card [hideClose]="true">
        <ng-container content>
          <div class="education">
            <h2>{{ EDUCATION.title }}</h2>
            <div class="steps">
              @for (step of EDUCATION.steps; track step.number) {
                <div class="step">
                  <span class="number">{{ step.number }}</span>
                  <p>{{ step.text }}</p>
                </div>
              }
            </div>
            <button
              mat-flat-button
              data-testid="add-ai-agent-button"
              class="primary_button large_button"
              (click)="createAgent()"
            >
              Create your first agent
            </button>
          </div>
        </ng-container>
      </knowledge-card>
    }
  }
</div>
