import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class StudioGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const { agentId } = route.params;
    await this.studioService.initialize();
    const [application, agents] = await Promise.all([
      firstValueFrom(this.applicationService.observeCurrentApplication()),
      firstValueFrom(this.studioService.observeAgents()),
    ]);

    if (!application) {
      return this.router.createUrlTree(['/application']);
    }

    const agent = agents?.profiles[agentId];
    return agent ? true : this.router.createUrlTree(['/application', application.appId, 'studio']);
  }
}
