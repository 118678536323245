import { Injectable, TemplateRef } from '@angular/core';
import { ComponentPortal, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { ComponentType } from '@angular/cdk/overlay';
import { assertTruthy } from 'assertic';

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  private portalOutlet: PortalOutlet | undefined;

  setPortalOutlet(outlet: PortalOutlet): void {
    this.portalOutlet = outlet;
  }

  attach(componentOrTemplate: ComponentType<unknown> | TemplateRef<unknown>): void {
    assertTruthy(this.portalOutlet, 'Portal outlet not set!');

    this.portalOutlet.detach();
    if (componentOrTemplate instanceof TemplateRef) {
      const templatePortal = new TemplatePortal(
        componentOrTemplate,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        null!,
      );
      this.portalOutlet.attach(templatePortal);
    } else {
      const componentPortal = new ComponentPortal(componentOrTemplate, null);
      this.portalOutlet.attach(componentPortal);
    }
  }

  detach(): void {
    this.portalOutlet?.detach();
  }
}
