import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { getMessageFromError, truthy } from 'assertic';

type DeleteOptions = {
  contextId: string;
};

export class FileAbility extends BaseAbility<undefined, undefined, DeleteOptions> {
  static override metadata = {
    name: 'File',
    description: "Add a file to your agent's knowledge base",
    icon: 'pdf',
  };

  override async onConnect(agentId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.globalUiService.showDialogWithForm<{ title: string; name: string; file: File }>(
        {
          title: 'Add Knowledge',
          textLines: ["Add a file to your agent's knowledge base"],
          submitButtonText: 'Add Knowledge',
          formElements: [
            {
              type: 'input',
              required: true,
              nameInForm: 'title',
              label: 'Knowledge title',
            },
            {
              type: 'file',
              required: true,
              nameInForm: 'file',
              label: 'File upload',
              fileTypes: 'pdf, docx, html, and any text file',
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            if (!data) return;
            const title = data.title;
            const value = data.file.name;
            try {
              await this.studioService.addContext(
                agentId,
                title,
                {
                  type: 'file',
                  data: truthy(value, 'NO_CONTEXT_VALUE'),
                },
                (data as { file?: File }).file,
              );
              this.snackBar.success('Knowledge added');
              resolve(true);
            } catch (error) {
              const message = getMessageFromError(error, 'Unable to add knowledge');
              this.snackBar.warning(message);
              reject(message);
            }
          },
        },
        true,
      );
    });
  }

  override async onDelete(agentId: string, options: DeleteOptions): Promise<boolean> {
    const { contextId } = options;

    return new Promise(async (resolve, reject) => {
      this.globalUiService.showConfirmationDialog(
        'Arrrrr you sure?',
        `Deleting this knowledge cannot be undone or recovered, and it will be removed from this agent.`,
        'Delete',
        async () => {
          try {
            await this.studioService.deleteContext(agentId, contextId);
            resolve(true);
          } catch (e) {
            reject(e);
          }
        },
      );
    });
  }
}
