import { Injectable } from '@angular/core';
import {
  AI_AGENTS_INTEGRATION_ID,
  AiChatbotContext,
  AiChatbotProfileMetadata,
  AiClient,
  Squid,
} from '@squidcloud/client';
import { truthy } from 'assertic';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ApplicationService } from '../application/application.service';
import { SnackBarService } from '../global/services/snack-bar.service';
import { generateId } from '@squidcloud/internal-common/public-utils/id-utils';
import { AiChatbotProfile, AiChatbotProfiles } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';

@Injectable({
  providedIn: 'root',
})
export class StudioService {
  private readonly flyoutSubject = new BehaviorSubject<boolean>(false);
  private readonly schemaSubject = new BehaviorSubject<AiChatbotProfiles | undefined>(undefined);
  private client!: AiClient;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly snackBar: SnackBarService,
    private readonly squid: Squid,
  ) {}

  async initialize(): Promise<void> {
    const appSquid = await this.applicationService.getApplicationSquid();
    this.client = appSquid.ai();

    let agents: AiChatbotProfiles | undefined;

    try {
      agents = await this.getAgents();
    } catch (e) {
      this.snackBar.warning('Unable to discover schema, please try again later');
      console.error('Unable to discover schema for integration: ', AI_AGENTS_INTEGRATION_ID, e);
    } finally {
      if (!agents) {
        agents = { profiles: {} };
      }
      this.schemaSubject.next(agents);
    }
  }

  observeAgents(): Observable<AiChatbotProfiles | undefined> {
    return this.schemaSubject.asObservable();
  }

  observeAgent(agentId: string): Observable<AiChatbotProfile | undefined> {
    return this.schemaSubject.asObservable().pipe(map(schema => schema?.profiles[agentId]));
  }

  getSchemaOrFail(): AiChatbotProfiles {
    return truthy(this.schemaSubject.value, 'NO_SCHEMA');
  }

  getAgentSchemaOrFail(agentId: string): AiChatbotProfile {
    const schema = this.getSchema();
    return truthy(schema.profiles[agentId], 'No agent found');
  }

  chat(agentId: string, prompt: string): Observable<string> {
    return this.client.agent(agentId).chat(prompt);
  }

  async addContext(agentId: string, title: string, context: AiChatbotContext, file?: File): Promise<void> {
    const id = generateId();
    await this.client.agent(agentId).context(id).insert({ title, context }, file);

    const schema = this.getSchema();
    schema.profiles[agentId].contexts[id] = { title, text: context.data, type: context.type, preview: true };
    if (!schema.profiles[agentId].contexts) {
      schema.profiles[agentId].contexts = {};
    }
    this.schemaSubject.next(schema);
  }

  async deleteContext(agentId: string, id: string): Promise<void> {
    await this.client.agent(agentId).context(id).delete();

    const schema = this.getSchema();
    delete schema.profiles[agentId].contexts[id];
    this.schemaSubject.next(schema);
  }

  async addInstruction(agentId: string, instruction: string): Promise<void> {
    const id = generateId();
    await this.client.agent(agentId).instruction(id).insert({ instruction });

    const schema = this.getSchema();
    if (!schema.profiles[agentId].instructions) {
      schema.profiles[agentId].instructions = {};
    }
    schema.profiles[agentId].instructions[id] = instruction;
    this.schemaSubject.next(schema);
  }

  async updateInstruction(agentId: string, id: string, instruction: string): Promise<void> {
    await this.client.agent(agentId).instruction(id).update({ instruction });

    const schema = this.getSchema();
    schema.profiles[agentId].instructions[id] = instruction;
    this.schemaSubject.next(schema);
  }

  async deleteInstruction(agentId: string, id: string): Promise<void> {
    await this.client.agent(agentId).instruction(id).delete();

    const schema = this.getSchema();
    delete schema.profiles[agentId].instructions[id];
    this.schemaSubject.next(schema);
  }

  async addAgent(agentId: string, data: Omit<AiChatbotProfileMetadata, 'instructions'>): Promise<void> {
    await this.client.agent(agentId).insert(data);

    const schema = this.getSchema();
    schema.profiles[agentId] = {
      ...data,
      contexts: {},
      instructions: {},
    };
    this.schemaSubject.next(schema);
  }

  async updateAgent(agentId: string, data: Partial<Omit<AiChatbotProfileMetadata, 'instructions'>>): Promise<void> {
    const schema = this.getSchema();
    const profile = schema.profiles[agentId];

    await this.client.agent(agentId).update(data);

    schema.profiles[agentId] = {
      ...profile,
      ...data,
    };

    this.schemaSubject.next(schema);
  }

  async deleteAgent(agentId: string): Promise<void> {
    await this.client.agent(agentId).delete();
    const schema = this.getSchema();
    delete schema.profiles[agentId];
    this.schemaSubject.next(schema);
  }

  private async getAgents(): Promise<AiChatbotProfiles> {
    return await callBackendExecutable(this.squid, 'getAiChatbotProfiles', {
      integrationId: AI_AGENTS_INTEGRATION_ID,
      appId: this.applicationService.getCurrentApplicationOrFail().appId,
    });
  }

  private getSchema(): AiChatbotProfiles {
    return truthy(this.schemaSubject.getValue(), 'No schema found');
  }

  openFlyout(): void {
    this.flyoutSubject.next(true);
  }

  closeFlyout(): void {
    this.flyoutSubject.next(false);
  }

  observeFlyout(): Observable<boolean> {
    return this.flyoutSubject.asObservable();
  }
}
