<div class="card">
  <button class="header" (click)="handleToggle()">
    <div class="icon_and_name">
      <span class="name">{{ status.title }}</span>
    </div>
    <div class="toggle">
      <mat-icon [ngClass]="{ expanded: expanded }" class="toggle_icon" svgIcon="chevron_right_icon"></mat-icon>
    </div>
  </button>
  <div [ngClass]="{ expanded: expanded }" class="content_wrapper">
    <div class="content">
      @if (status.tags) {
        <app-json-editor [options]="editorOptions" [data]="status.tags"></app-json-editor>
      }
    </div>
  </div>
</div>
