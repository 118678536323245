import {
  MmCreateMatchMakerRequest,
  MmDeleteEntityRequest,
  MmDeleteMatchMakerRequest,
  MmFindMatchesForEntityRequest,
  MmFindMatchesForEntityResponse,
  MmFindMatchesRequest,
  MmFindMatchesResponse,
  MmInsertEntityRequest,
  MmListEntitiesRequest,
} from '../../internal-common/src/types/ai-matchmaking.types';
import { RpcManager } from './rpc.manager';
import {
  MmEntity,
  MmEntityMatch,
  MmFindMatchesOptions,
  MmListEntitiesOptions,
  MmMatchMaker,
} from '../../internal-common/src/public-types/ai-matchmaking.types';

/**
 * Client for the AI Matchmaking service. This service allows you to create matchmakers and insert entities into them.
 * The service will then find matches for the entities.
 */
export class AiMatchMakingClient {
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Creates a new matchmaker with the given id, description, and categories.
   * @param matchMaker The matchmaker to create.
   */
  async createMatchMaker(matchMaker: MmMatchMaker): Promise<MatchMaker> {
    await this.rpcManager.post<void, MmCreateMatchMakerRequest>('matchMaking/createMatchMaker', {
      matchMaker,
    });
    return new MatchMaker(matchMaker.id, this.rpcManager);
  }

  /**
   * Retrieves an existing matchmaker by its ID.
   */
  getMatchMaker(matchMakerId: string): MatchMaker {
    return new MatchMaker(matchMakerId, this.rpcManager);
  }

  async listMatchMakers(): Promise<Array<MmMatchMaker>> {
    return await this.rpcManager.post<Array<MmMatchMaker>, unknown>('matchMaking/listMatchMakers', {});
  }
}

/**
 * Represents a matchmaker. You can insert entities into the matchmaker and find matches for them.
 */
export class MatchMaker {
  /** @internal */
  constructor(
    public id: string,
    private readonly rpcManager: RpcManager,
  ) {}

  /** Adds a new entity to the matchmaker - replaces existing entity with the same ID if exists. */
  async insertEntity(entity: MmEntity): Promise<void> {
    await this.rpcManager.post<void, MmInsertEntityRequest>('matchMaking/insertEntity', {
      matchMakerId: this.id,
      entity,
    });
  }

  async delete(): Promise<void> {
    await this.rpcManager.post<void, MmDeleteMatchMakerRequest>('matchMaking/deleteMatchMaker', {
      matchMakerId: this.id,
    });
  }

  async deleteEntity(entityId: string): Promise<void> {
    await this.rpcManager.post<void, MmDeleteEntityRequest>('matchMaking/deleteEntity', {
      entityId,
    });
  }

  /** Finds matches for the given entity id. */
  async findMatches(entityId: string, options: MmFindMatchesOptions = {}): Promise<Array<MmEntityMatch>> {
    const response = await this.rpcManager.post<MmFindMatchesResponse, MmFindMatchesRequest>(
      'matchMaking/findMatches',
      {
        entityId,
        options,
      },
    );
    return response.matches;
  }

  /** Finds matches for the given entity. */
  async findMatchesForEntity(
    entity: Omit<MmEntity, 'metadata' | 'id'>,
    options: MmFindMatchesOptions = {},
  ): Promise<Array<MmEntityMatch>> {
    const response = await this.rpcManager.post<MmFindMatchesForEntityResponse, MmFindMatchesForEntityRequest>(
      'matchMaking/findMatchesForEntity',
      {
        matchMakerId: this.id,
        entity,
        options,
      },
    );
    return response.matches;
  }

  /**
   * Lists entities in the matchmaker that match the given category ID and the provided options.
   * The results are ordered by the entity id.
   */
  async listEntities(categoryId: string, options: MmListEntitiesOptions = {}): Promise<Array<MmEntity>> {
    return await this.rpcManager.post<Array<MmEntity>, MmListEntitiesRequest>('matchMaking/listEntities', {
      categoryId,
      options,
      matchMakerId: this.id,
    });
  }
}
