import { Injectable } from '@angular/core';
import {
  AiAgentClient,
  AiChatbotContext,
  AiChatbotProfileMetadata,
  AppId,
  IntegrationId,
  Squid,
} from '@squidcloud/client';
import { truthy } from 'assertic';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationService } from '../../../application/application.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { generateId } from '@squidcloud/internal-common/public-utils/id-utils';
import {
  AiAgentsIntegrationConfig,
  AiChatbotIntegrationConfig,
  AiChatbotProfiles,
} from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';

@Injectable({
  providedIn: 'root',
})
export class AiChatbotProfilesService {
  private readonly profilesSubject = new BehaviorSubject<AiChatbotProfiles | undefined>(undefined);
  private chatbot!: AiAgentClient;
  private integration: AiChatbotIntegrationConfig | AiAgentsIntegrationConfig | undefined;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly snackBar: SnackBarService,
    private readonly squid: Squid,
  ) {}

  async initializeAiSchema(integration: AiChatbotIntegrationConfig | AiAgentsIntegrationConfig): Promise<void> {
    const appSquid = await this.applicationService.getApplicationSquid();
    this.chatbot = appSquid.ai().chatbot(integration.id);

    this.integration = integration;

    let profiles: AiChatbotProfiles | undefined;

    try {
      profiles = await this.getChatbotProfile();
    } catch (e) {
      this.snackBar.warning('Unable to discover schema, please try again later');
      console.error('Unable to discover schema for integration: ', integration, e);
    } finally {
      if (!profiles) {
        profiles = { profiles: {} };
      }
      this.profilesSubject.next(profiles);
    }
  }

  get integrationId(): string {
    return truthy(this.integration, 'Integration is undefined').id;
  }

  observeProfiles(): Observable<AiChatbotProfiles | undefined> {
    return this.profilesSubject.asObservable();
  }

  getSchemaOrFail(): AiChatbotProfiles {
    return truthy(this.profilesSubject.value, 'NO_SCHEMA');
  }

  chat(profileId: string, prompt: string): Observable<string> {
    return this.chatbot.profile(profileId).chat(prompt);
  }

  async addContext(profileId: string, title: string, context: AiChatbotContext, file?: File): Promise<void> {
    const id = generateId();
    await this.chatbot.profile(profileId).context(id).insert({ title, context }, file);

    const schema = this.getSchema();
    schema.profiles[profileId].contexts[id] = { title, text: context.data, preview: true };
    if (!schema.profiles[profileId].contexts) {
      schema.profiles[profileId].contexts = {};
    }
    this.profilesSubject.next(schema);
  }

  async deleteContext(profileId: string, id: string): Promise<void> {
    await this.chatbot.profile(profileId).context(id).delete();

    const schema = this.getSchema();
    delete schema.profiles[profileId].contexts[id];
    this.profilesSubject.next(schema);
  }

  async addInstruction(profileId: string, instruction: string): Promise<void> {
    const id = generateId();
    await this.chatbot.profile(profileId).instruction(id).insert({ instruction });

    const schema = this.getSchema();
    if (!schema.profiles[profileId].instructions) {
      schema.profiles[profileId].instructions = {};
    }
    schema.profiles[profileId].instructions[id] = instruction;
    this.profilesSubject.next(schema);
  }

  async updateInstruction(profileId: string, id: string, instruction: string): Promise<void> {
    await this.chatbot.profile(profileId).instruction(id).update({ instruction });

    const schema = this.getSchema();
    schema.profiles[profileId].instructions[id] = instruction;
    this.profilesSubject.next(schema);
  }

  async deleteInstruction(profileId: string, id: string): Promise<void> {
    await this.chatbot.profile(profileId).instruction(id).delete();

    const schema = this.getSchema();
    delete schema.profiles[profileId].instructions[id];
    this.profilesSubject.next(schema);
  }

  async addAgent(agentId: string, data: Omit<AiChatbotProfileMetadata, 'instructions'>): Promise<void> {
    await this.chatbot.profile(agentId).insert(data);

    const schema = this.getSchema();
    schema.profiles[agentId] = {
      ...data,
      contexts: {},
      instructions: {},
    };
    this.profilesSubject.next(schema);
  }

  async updateProfile(profileId: string, data: Partial<Omit<AiChatbotProfileMetadata, 'instructions'>>): Promise<void> {
    const schema = this.getSchema();
    const profile = schema.profiles[profileId];

    await this.chatbot.profile(profileId).update(data);

    schema.profiles[profileId] = {
      ...profile,
      ...data,
    };

    this.profilesSubject.next(schema);
  }

  async deleteProfile(profileId: string): Promise<void> {
    await this.chatbot.profile(profileId).delete();
    const schema = this.getSchema();
    delete schema.profiles[profileId];
    this.profilesSubject.next(schema);
  }

  private async getChatbotProfile(): Promise<AiChatbotProfiles> {
    const { appId } = this.applicationService.getCurrentApplicationOrFail();
    return AiChatbotProfilesService.getProfiles(this.squid, appId, this.integrationId);
  }

  static async getProfiles(squid: Squid, appId: AppId, integrationId: IntegrationId): Promise<AiChatbotProfiles> {
    return await callBackendExecutable(squid, 'getAiChatbotProfiles', { appId, integrationId });
  }

  private getSchema(): AiChatbotProfiles {
    return truthy(this.profilesSubject.getValue(), 'No schema found');
  }
}
