@let agent = agent$ | async;

<ng-template #studioFlyout>
  <studio-flyout
    [agentId]="agentId"
    [eligibleAiFunctions]="eligibleAiFunctions"
    [eligibleConnectedAgentIds]="eligibleConnectedAgentIds"
    [eligibleConnectors]="eligibleConnectors"
    [existingConnectors]="existingConnectors"
    (create)="onCreate($event.type, $event.options)"
    (connect)="onConnect($event.type, $event.options)"
  ></studio-flyout>
</ng-template>

@if (agent) {
  <div class="create">
    <div class="left">
      <form [formGroup]="form" class="form">
        @let modelControl = form.controls['model'];
        <mat-form-field
          [hideRequiredMarker]="true"
          [ngClass]="{ valid: modelControl.valid && modelControl.touched }"
          appearance="outline"
          floatLabel="always"
          class="form_field dialog_form_field select_field alt"
        >
          <mat-label>Model</mat-label>
          <mat-select formControlName="model" class="select_box gray_select">
            @for (model of modelOptions; track model.value) {
              <mat-option [value]="model.value">{{ model.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        @let instructionsControl = form.controls['instructions'];
        <mat-form-field
          [hideRequiredMarker]="true"
          [ngClass]="{ valid: instructionsControl.valid && instructionsControl.touched }"
          appearance="outline"
          floatLabel="always"
          class="form_field dialog_form_field textarea_form_field alt"
        >
          <mat-label>Instructions</mat-label>
          <textarea
            name="instructions"
            formControlName="instructions"
            placeholder="Use natural language to write your instructions here"
            [cdkTextareaAutosize]="true"
            [cdkAutosizeMinRows]="10"
            autocomplete="off"
            matInput
          ></textarea>
        </mat-form-field>
      </form>
    </div>
    <div class="right">
      @if (hasAbilities) {
        <div class="header">
          <button mat-flat-button class="primary_button large_button add_button" (click)="addAbility()">
            Add Abilities
          </button>
        </div>
        <div class="categories">
          @if (getEntries(agent.contexts).length) {
            <abilities-category title="Knowledge Base">
              <div class="cards">
                @for (entry of getEntries(agent.contexts); track entry[1].title) {
                  <!-- Defaults to 'text' if the context type is not defined.-->
                  <abilities-card
                    [type]="entry[1].type || inferContextType(entry[1])"
                    [name]="entry[1].title"
                    [description]="entry[1].text"
                    (delete)="onDelete(entry[1].type ||  inferContextType(entry[1]), { contextId: entry[0] })"
                  />
                }
              </div>
            </abilities-category>
          }
          @for (entry of getEntries(connectedIntegrationsByCategory); track entry[0]) {
            <abilities-category [title]="getCategoryName(entry[0])">
              <div class="cards">
                @for (connectedIntegration of entry[1]; track connectedIntegration.integrationId) {
                  @if (isAbilityType(connectedIntegration.integrationType)) {
                    <abilities-card
                      [type]="connectedIntegration.integrationType"
                      [name]="connectedIntegration.integrationId"
                      [description]="connectedIntegration.description"
                      (edit)="editConnector(connectedIntegration.integrationId, connectedIntegration.integrationType)"
                      (delete)="
                        deleteConnector(connectedIntegration.integrationId, connectedIntegration.integrationType)
                      "
                    />
                  }
                }
              </div>
            </abilities-category>
          }
          @if (agent.connectedAgents?.length) {
            <abilities-category title="Connected Agents">
              <div class="cards">
                @for (connectedAgent of agent.connectedAgents; track connectedAgent.agentId) {
                  <abilities-card
                    type="connected_agent"
                    [name]="connectedAgent.agentId"
                    [description]="connectedAgent.description"
                    (edit)="onEdit('connected_agent', { connectedAgentId: connectedAgent.agentId })"
                    (delete)="onDelete('connected_agent', { connectedAgentId: connectedAgent.agentId })"
                  />
                }
              </div>
            </abilities-category>
          }
          @if (agent.functions?.length) {
            <abilities-category title="AI Functions">
              <div class="cards">
                @for (fn of agent.functions; track fn) {
                  <abilities-card type="ai_function" [name]="fn" (delete)="onDelete('ai_function', { fn: fn })" />
                }
              </div>
            </abilities-category>
          }
        </div>
      } @else {
        <knowledge-card [hideClose]="true">
          <ng-container content>
            <div class="zero-state">
              <h2>Enhance your agent</h2>
              <div class="abilities">
                <div class="ability red">
                  <img src="/assets/integrations/pdf.svg" class="icon" />
                  Add Knowledge
                </div>
                <div class="ability green">
                  <img src="/assets/integrations/mongo.svg" class="icon" />
                  Add Connectors
                </div>
                <div class="ability purple">
                  <img src="/assets/integrations/ai_function.svg" class="icon" />
                  Add AI Functions
                </div>
              </div>
              <p>Build out your agent by adding abilities like knowledge, connectors, AI functions and more.</p>
              <button mat-flat-button class="primary_button large_button" (click)="addAbility()">
                Add Abilities
              </button>
            </div>
          </ng-container>
        </knowledge-card>
      }
    </div>
  </div>
}
