import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { AiProfileId, AppId, Pagination, Squid } from '@squidcloud/client';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { IntegrationService } from '@squidcloud/console-web/app/integrations/integration.service';
import { ActivatedRoute } from '@angular/router';
import { AiChatbotProfilesService } from '@squidcloud/console-web/app/integrations/schema/ai-chatbot-profiles/ai-chatbot-profiles.service';
import { MILLIS_PER_MINUTE } from '@squidcloud/internal-common/types/time-units';
import { LogsTable } from '@squidcloud/console-web/app/lib/logs/logs-table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuditLogEntryInDb, CpApplication, CpIntegration } from '@squidcloud/console-common/types/application.types';
import { AuditLogEvent } from '@squidcloud/internal-common/types/observability.types';
import { AuditLogEntryDialogComponent } from '@squidcloud/console-web/app/integrations/audit-logs/audit-log-entry-dialog/audit-log-entry-dialog.component';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-logs.component.html',
  styleUrl: './audit-logs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AuditLogsComponent extends LogsTable<AuditLogEntryInDb, AuditLogEntryInDb> implements OnInit {
  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();
  @Input() forcedAgentId?: AiProfileId;

  agentIdOptions: { label: string; value: string }[] | undefined = undefined;
  selectedAgentId: string | undefined = undefined;

  constructor(
    activatedRoute: ActivatedRoute,
    applicationService: ApplicationService,
    integrationService: IntegrationService,
    squid: Squid,
    cdr: ChangeDetectorRef,
    private readonly aiChatbotProfilesService: AiChatbotProfilesService,
    private readonly dialog: MatDialog,
  ) {
    super(activatedRoute, applicationService, integrationService, squid, cdr);
  }

  ngOnInit(): void {
    if (this.forcedAgentId) {
      this.selectedAgentId = this.forcedAgentId;
    }
    this.initialize();
  }

  onAgentSelectionChange(event: MatSelectChange): void {
    this.selectedAgentId = event.value;
    void this.executeQuery();
  }

  parseLogEntry(logEntryInDb: AuditLogEntryInDb): AuditLogEntryInDb {
    return logEntryInDb;
  }

  getPagination(appId: AppId): Pagination<AuditLogEntryInDb> {
    const queryBuilder = this.squid
      .collection<AuditLogEntryInDb>('audit_logs', 'clickhouse')
      .query()
      .eq('appId', appId)
      .eq('name', 'ai_agent_prompt')
      .sortBy('timestamp', false);

    if (this.logDisplayPeriodInMinutes) {
      const fromDate = new Date(Date.now() - this.logDisplayPeriodInMinutes * MILLIS_PER_MINUTE);
      queryBuilder.gte('timestamp', fromDate);
    }

    if (this.selectedAgentId !== undefined) {
      queryBuilder.eq('tags.agentId', this.selectedAgentId);
    }

    return queryBuilder.dereference().paginate({ pageSize: 100, subscribe: false });
  }

  async handleApplicationChange(
    _: CpApplication | undefined | null,
    integration: CpIntegration | undefined | null,
  ): Promise<void> {
    if (!integration) return;
    await this.aiChatbotProfilesService.initializeAiSchema(integration);

    const schema = this.aiChatbotProfilesService.getSchemaOrFail();

    this.agentIdOptions = Object.keys(schema.profiles).map(profile => ({
      label: profile,
      value: profile,
    }));
    this.selectedAgentId = undefined;
  }

  showLogEntryDialog(logEntry: AuditLogEvent): void {
    const config: MatDialogConfig = {
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: { logEntry },
    };

    this.dialog.open(AuditLogEntryDialogComponent, config);
  }
}
