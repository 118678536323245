@let application = applicationService.currentApplication$ | async ;
<div class="integrations_page">
  <div class="container">
    <page-header [title]="application ? application.name : 'No application'"
                 subtitle="Connectors"
                 description="Adding connectors allows Squid to control and modify various external databases, third party applications and services. If you do not see what you are looking for please request it."
                 [withBottomBorder]="!application">
    </page-header>

    @if (application) {
      <div class="tab_group_container">
        <mat-tab-group #tabGroup (selectedTabChange)="changeUrl(tabGroup.selectedIndex)"
                       [(selectedIndex)]="selectedIndex"
                       animationDuration="0ms"
                       color="primary"
                       disableRipple="true" mat-align-tabs="start"
                       mat-stretch-tabs="false">
          <mat-tab>
            <ng-template mat-tab-label>
              <div data-testid="integrations-tab-label-current-integrations">Current Connectors</div>
            </ng-template>
            <ng-template matTabContent>
              <current-integrations class="tab"></current-integrations>
            </ng-template>
          </mat-tab>
          <mat-tab class="tab">
            <ng-template mat-tab-label>
              <div data-testid="integrations-tab-label-available-integrations">Available Connectors</div>
            </ng-template>
            <ng-template matTabContent>
              <available-integrations class="tab"></available-integrations>
            </ng-template>
          </mat-tab>
        </mat-tab-group>

        <div class="actions">
          <button (click)="showRequestIntegrationDialog()"
                  class="request_integration_button secondary_button large_button"
                  mat-flat-button>
            Request Connector
          </button>
        </div>
      </div>
    }
  </div>

  @if (!application) {
    <no-application-zero-state
      title="Arrrr no connectors yet..."
      description="Connectors require an application, it looks like you do not have one yet.">
    </no-application-zero-state>
  }
</div>
