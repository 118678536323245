@if (form && integrationForm && integrationType) {
  <div class="integration_form_page">
    @let isAdmin = 'ADMIN' | minRole ;
    <div class="container">
      <portal class="flex_shrink_0" [emitter]="headerTemplateChange" [teleport]="isEdit">
        <integration-header [integrationType]="integrationType"
                            [title]="isEdit ? integrationId! : 'Add Connector'"
                            [step]="isEdit ? [-1, -1] : canEditSchema() ? [1, 2] : [1, 1]"
                            [secondaryTitle]="getSecondaryTitle(integrationForm)"
                            [description]="isEdit ? undefined : integrationForm.description"
                            [integrationPageText]="integrationForm.integrationPageText"
                            [withTabs]="isEdit">
          <ng-container buttons>
            @if (isTestConnectionButtonVisible) {
              <ng-container [ngSwitch]="buttonType()">
                <button *ngSwitchCase="'flat'"
                        (click)="performDataConnectionTest()"
                        [disabled]="form.invalid || testConnectionInProgress() || formSubmitting"
                        [ngClass]="getTestConnectionButtonClasses()"
                        data-testid="test-connection-button"
                        mat-flat-button
                        style="width: 160px;">
                  <loading-indicator-overlay [isLoading]="testConnectionInProgress()" color="blue">
                    {{ getTestConnectionButtonText() }}
                    @if (testConnectionInProgress()) {
                      <mat-icon [svgIcon]="testConnectionIcon()" />
                    }
                  </loading-indicator-overlay>
                </button>
                <button *ngSwitchCase="'stroked'"
                        (click)="performDataConnectionTest()"
                        [disabled]="form.invalid || testConnectionInProgress() || formSubmitting"
                        [ngClass]="getTestConnectionButtonClasses()"
                        [matTooltip]="isError() ? 'Having trouble connecting? You may need to give access to Squid\'s IP addresses on your database.' : ''"
                        data-testid="test-connection-button"
                        mat-stroked-button
                        style="width: 160px;">
                  {{ getTestConnectionButtonText() }}
                  <mat-icon [svgIcon]="testConnectionIcon()" />
                </button>
              </ng-container>
            }
            <div class="inline_block" [matTooltip]="!isAdmin ? 'You need to be an admin to edit an integration' : ''">
              <button (click)="submitIntegrationForm()"
                      [disabled]="!isAdmin || form.invalid || (form.pristine && !toggled) || formSubmitting"
                      class="next_button primary_button large_button"
                      mat-flat-button
                      type="submit"
                      [attr.data-testid]="isEdit? 'save-integration-button' : 'add-integration-button'"
                      style="width: 160px;"
              >
                <loading-indicator-overlay [isLoading]="formSubmitting" color="blue">
                  {{ formSubmitting ? '' : isEdit ? 'Save Connector' : 'Add Connector' }}
                  @if (formSubmitting) {
                    <mat-icon [svgIcon]="testConnectionIcon()" />
                  }
                </loading-indicator-overlay>
              </button>
            </div>
          </ng-container>
        </integration-header>
      </portal>

      <form [formGroup]="form" class="form"
            [matTooltip]="!isAdmin ? 'You need to be an admin to edit an integration' : ''"
            [matTooltipPosition]="'above'">
        @for (section of integrationForm.sections; track $index) {
          <section class="section">
            @if (isToggleableSection(section)) {
              <div class="section_toggle">
                <div class="title_and_description">
                  <div class="title">{{ section.title }}</div>
                  <div class="description">{{ section.description }}</div>
                </div>
                <div class="form_field section_toggle_button_container">
                  <mat-slide-toggle (click)="toggleSection(section)" [checked]="section.enabled"
                                    class="section_toggle_button" color="primary"></mat-slide-toggle>
                </div>
              </div>
            }
            @if (section.type === 'default' || (isToggleableSection(section) && section.enabled)) {
              @for (field of section.fields; track field.name) {
                @let control = form.controls[field.pathInIntegration] ;
                @if (isComponentIntegrationFormField(field)) {
                  <div class="component_field">
                    @if (field.component.type === 'api-injection') {
                      <injection-table-form class="mb_24"
                                            [control]="control"
                                            [name]="field.name"
                                            [description]="field.description" />
                    }
                  </div>
                } @else {
                  <div class="field_name_and_field">
                    <div class="name_and_description">
                      <div class="name">
                        {{ field.name }}
                        @if (field.required) {
                          <span class="required">{{ requiredText }}</span>
                        }
                      </div>
                      @if (field.description) {
                        <span class="description" [innerHTML]="field.description"></span>
                      }
                    </div>

                    @if (isEdit && field.pathInIntegration === 'id') {
                      <div class="immutable_card">
                        <input-card>
                          {{ control.value }}
                          <div class="buttons">
                            <button (click)="copyIntegrationId()"
                                    title="Copy connector id"
                                    class="medium_menu_button secondary_icon_button" mat-flat-button>
                              <mat-icon class="icon" svgIcon="copy_icon"></mat-icon>
                            </button>
                          </div>
                        </input-card>
                      </div>
                    } @else if (isInputIntegrationFormField(field)) {
                      <mat-form-field [hideRequiredMarker]="true"
                                      [ngClass]="{valid: control.valid && control.touched}"
                                      appearance="outline"
                                      class="form_field">
                        <mat-label labelWithPrefix>{{ field.placeholder }}</mat-label>
                        <input [formControlName]="field.pathInIntegration" [name]="field.pathInIntegration"
                               [attr.data-testid]="getDataTestId(field)"
                               autocomplete="off"
                               matInput>
                        @if (control.errors?.['error'] && control.touched) {
                          <mat-hint class="error_hint">{{ control.errors?.['error'] }}</mat-hint>
                        }
                        @if (field.prefixIcon) {
                          <mat-icon class="prefix_icon" matPrefix svgIcon="link_icon"></mat-icon>
                        }
                        @if (field.tooltip) {
                          <mat-icon [matTooltip]="field.tooltip.copy"
                                    class="tooltip_suffix_icon"
                                    matSuffix
                                    matTooltipClass="dark"
                                    svgIcon="tooltip_icon"></mat-icon>
                        }
                      </mat-form-field>
                    } @else if (isSelectIntegrationFormField(field)) {
                      <mat-form-field
                        [hideRequiredMarker]="true"
                        [ngClass]="{valid: control.valid && control.touched}"
                        appearance="outline"
                        class="form_field">
                        <mat-label>{{ field.placeholder }}</mat-label>
                        <mat-select [formControlName]="field.pathInIntegration" class="select_box gray_select"
                                    [attr.data-testid]="getDataTestId(field)"
                        >
                          @for (option of field.options; track option.label) {
                            <mat-option [value]="option.value"
                                        [attr.data-testid]="getDataTestId({name:option.label})">
                              {{ option.label }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    } @else if (isBooleanIntegrationFormField(field)) {
                      <div class="form_field boolean_field_container">
                        <mat-slide-toggle
                          [formControlName]="field.pathInIntegration"
                          [name]="field.pathInIntegration"
                          class="section_toggle_button"
                          color="primary"></mat-slide-toggle>
                      </div>
                    } @else if (isSecretIntegrationFormField(field)) {
                      <mat-form-field [hideRequiredMarker]="true"
                                      [ngClass]="{valid: control.valid && control.touched}"
                                      appearance="outline"
                                      class="form_field">
                        <select-secret [formControlName]="field.pathInIntegration"
                                       [placeholder]="field.placeholder"
                                       [defaultValue]="field.defaultValue"
                                       [required]="field.required"
                                       [attr.data-testid]="getDataTestId(field)"
                        ></select-secret>
                      </mat-form-field>
                    }
                  </div>
                }
              }
            }
          </section>
        }
      </form>

      @if (isEdit && isAdmin) {
        <div class="danger_zone">
          <div class="danger_zone_title">Danger Zone</div>
          <action-card
            title="Delete integration"
            description="Once this connector is deleted there is no going back. A sunken ship cannot be resurfaced."
            (action)="deleteIntegration()"
            cta="Delete This Connector"
            [warning]="true" />
        </div>
      }
    </div>
  </div>
}
