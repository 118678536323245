import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { Router } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { AbilityMetadata } from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { Squid } from '@squidcloud/client';

export type AbilityConfig = {
  applicationService: ApplicationService;
  studioService: StudioService;
  snackBar: SnackBarService;
  globalUiService: GlobalUiService;
  router: Router;
  squid: Squid;
};

export class BaseAbility<TCreateOptions = undefined, TConnectOptions = undefined, TEditOptions = undefined> {
  static metadata: AbilityMetadata;

  protected readonly applicationService: ApplicationService;
  protected readonly studioService: StudioService;
  protected readonly snackBar: SnackBarService;
  protected readonly globalUiService: GlobalUiService;
  protected readonly router: Router;
  protected readonly squid: Squid;

  public constructor({ applicationService, studioService, snackBar, globalUiService, router, squid }: AbilityConfig) {
    this.applicationService = applicationService;
    this.studioService = studioService;
    this.snackBar = snackBar;
    this.globalUiService = globalUiService;
    this.router = router;
    this.squid = squid;
  }

  async onCreate(_agentId: string, _options?: TCreateOptions): Promise<boolean> {
    return true;
  }

  async onConnect(_agentId: string, _options?: TConnectOptions): Promise<boolean> {
    return true;
  }

  async onEdit(_agentId: string, _options?: TEditOptions): Promise<boolean> {
    return true;
  }

  async onDelete(_agentId: string, _options?: TEditOptions): Promise<boolean> {
    return true;
  }
}
