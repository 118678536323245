@let agent = agent$ | async;
@let application = application$ | async;

@if (agent && application) {
  <div class="agent">
    <div class="header">
      <div class="id_and_badge">
        <h1>{{ agentId }}</h1>
        @if (agent.isPublic) {
          <div class="outline_badge public">Public</div>
        } @else {
          <div class="outline_badge private">Private</div>
        }
      </div>
      <div class="buttons">
        <button
          class="deploy primary_button large_button"
          mat-flat-button
          (click)="showEmbedWidgetDialog()"
        >
          Use Agent
        </button>
        <button class="edit" (click)="showEditAgentDialog()">
          <mat-icon class="edit_icon" svgIcon="edit_icon" />
        </button>
        <a class="back" [routerLink]="['/application', application.appId, 'studio']">
          <mat-icon class="back_icon" svgIcon="close_icon" />
        </a>
      </div>
    </div>

    <mat-tab-group
      #tabGroup
      (selectedTabChange)="changeUrl(tabGroup.selectedIndex)"
      [selectedIndex]="selectedIndex"
      animationDuration="0ms"
      color="primary"
      disableRipple="true"
      mat-align-tabs="start"
      mat-stretch-tabs="false"
      preserveContent="true"
    >
      @for (tab of tabs; track tab.id) {
        <mat-tab>
          <ng-template matTabLabel>
            <div [attr.data-testid]="tab.id + '-tab-label'">{{ tab.name }}</div>
          </ng-template>
          <ng-template matTabContent>
            <ng-container *ngComponentOutlet="tab.component"></ng-container>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
  </div>
}
