import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbilityType, getAbilityMetadata } from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'abilities-card',
  templateUrl: './abilities-card.component.html',
  styleUrl: './abilities-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AbilitiesCardComponent {
  @Input({ required: true }) type!: AbilityType;
  @Input() name: string | undefined = undefined;
  @Input() description: string | undefined = undefined;
  @Input() expanded = false;

  @Output() add = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  getImage(): string {
    return `/assets/integrations/${getAbilityMetadata(this.type).icon}.svg`;
  }

  getName(): string | undefined {
    return this.name || getAbilityMetadata(this.type).name;
  }

  getDescription(): string | undefined {
    return this.description || getAbilityMetadata(this.type).description;
  }

  handleToggle(): void {
    this.expanded = !this.expanded;
  }

  handlePlus(event: Event): void {
    event.stopPropagation();
    this.add.emit();
  }

  get isMenuOpen(): boolean {
    return this.menuTrigger?.menuOpen ?? false;
  }
}
